import React from 'react'
import './Home.css'
// import demoImage from './Img/demo.webp'  // Correctly import the image file
import Product_under_300 from '../productstop/product_under_300';
// import Register from '../Register/Register';
import Best_Deal from '../Bestdeals/Best_Deal';
import Featured_Brands from '../Featuredbrands/Featured_Brands';
import Banner from '../Banner/Banner';
import Categories from '../Categories/Categories';
// import Login from '../Login/Login';
// import Cart from '../Cart/Cart';
// import ProductList from '../ProductList/ProductList';
// import ProductFilter from '../ProductFilter/ProductFilter';
// import ProductBuyPage from '../ProductBuyPage/ProductBuyPage';
// import HoverDropdown from '../HoverDropdown/HoverDropdown';
// import Navbar from '../Navbar/Navbar';
// import Account from '../Account/Account';
// import MultilevelDropdown from '../MultilevelDropdown/MultilevelDropdown'

function Home() {
      
      return (
            <div>
                 <div className=''>  
                 </div>
                  {/*  */}
                  <div className=''><Categories /></div>
                  <div className=''> <Banner /></div>
                  <div className=''><Product_under_300 /></div>
                  <div className='container-fluid ' >

                        <div className='row'>
                              <div className='col-lg-4 col-6 mb-4'>
                                    <img className='img-fluid' src='https://rukminim2.flixcart.com/fk-p-flap/520/280/image/f56b4ff53ad5a0f6.jpg?q=20' />
                              </div>
                              <div className='col-lg-4 col-6 mb-4'>
                                    <img className='img-fluid' src='https://rukminim2.flixcart.com/fk-p-flap/520/280/image/f56b4ff53ad5a0f6.jpg?q=20' />
                              </div>
                              <div className='col-lg-4 col-12 mb-4'>
                                    <img className='img-fluid' src='https://rukminim2.flixcart.com/fk-p-flap/520/280/image/f56b4ff53ad5a0f6.jpg?q=20' />
                              </div>
                        </div>
                  </div>
                  <div className=''><Best_Deal /></div>
                  <div className=''><Featured_Brands /></div>




                  <div className='py-1'></div>
            </div>

      )
}

export default Home;

import React, { useState } from 'react';
import './BecomeSeller.css';
import BecomeNavbar from './BecomeNavbar';
import img1 from './demo1.png'
const BecomeSeller = () => {
  const [activeSection, setActiveSection] = useState('listProducts');

  const sections = [
    { id: 'createAccount', title: 'Create Account' },
    { id: 'listProducts', title: 'List Products' },
    { id: 'storageShipping', title: 'Storage & Shipping' },
    { id: 'receivePayments', title: 'Receive Payments' },
    { id: 'growFaster', title: 'Grow Faster' },
    { id: 'sellerApp', title: 'Seller App' },
  ];

  const handleSectionClick = (id) => {
    setActiveSection(id);
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className='become-seller'>
        <BecomeNavbar />
        <div className="container">
          <div className="row">
            <div className="col-3">
              {sections.map((section) => (
                <div
                  key={section.id}
                  className={`headlineb ${activeSection === section.id ? 'active' : ''}`}
                  onClick={() => handleSectionClick(section.id)}
                >
                  {section.title}
                </div>
              ))}
            </div>
            <div className="col-9 right-column">
              <div id="createAccount" className="content-section">
                <h2>Create Account</h2>

                <p>Creating your Ebazaar seller account is a quick process, taking less than 10 minutes, and requires only 3 documents. Follow the checklist to ensure a seamless account creation experience. By having these documents ready, you can streamline the account creation process and get started</p>
                <ul>
                  <li>* for selling in all categories except for book</li>
                  <li>** for selling under books category</li>
                </ul>

                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">Don’t have a GSTIN?</h5>
                  </div>
                  <div className="card-body">
                    <p className="card-text">Follow the steps below to generate for your online business:</p>
                    <div className='d-flex align-content-center justify-content-around'>
                      <div className="step">
                      {/* <a href="https://www.gst.gov.in" target="_blank" rel="noopener noreferrer">www.gst.gov.in</a> */}
                        <i className="fas fa-check-circle icon"></i>
                        <div className="step-text">Register / Login to </div>
                      </div>
                      <div className="step">
                        <i className="fas fa-check-circle icon"></i>
                        <div className="step-text">Fill in the GST Enrolment Application Form</div>
                      </div>
                      <div className="step">
                        <i className="fas fa-check-circle icon"></i>
                        <div className="step-text">Submit Enrolment Application</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="listProducts" className="content-section">
                <h2>List Products</h2>
                <p>What is a listing? A listing refers to the process of registering your product on the Ebazaar platform, making it visible to customers, and enabling them to view and purchase your product. It involves creating a detailed product page that includes essential information such as product title, description, images, pricing, and other relevant details. A well-crafted listing helps attract potential customers and facilitates the sale of your product on Ebazaar.</p>
                <img className='img-fluid mb-2' src={img1} />
                <p>Did you know that providing precise and comprehensive information about your product, along with clear and captivating images, can increase its visibility on our platform by up to 15%.</p>
              </div>
              <div id="storageShipping" className="content-section">
                <h2 className='headline-seller2'>Storage & Shipping</h2>
                <div>
                  <p>Congratulations on receiving your first order! When it comes to shipping your products to customers, Ebazaar understands the importance of fast and reliable delivery in secure packaging.</p>
                  <p>We provide two fulfilment options for you to choose from:</p>
                  <ul>
                    <li>Fulfilment by Ebazaar (FBE)</li>
                    <li>Non Fulfilment by Ebazaar (NFBE)</li>
                  </ul>
                  <p>Consider the fulfilment option that best suits your needs and preferences to ensure timely and secure delivery of your products to Ebazaar customers.</p>

                  <h5 className='headline-seller2'>Fulfilment by Ebazaar (FBE)</h5>
                  <p>Eliminate worries about storage, packing, shipping, and delivery by leveraging Ebazaar's Fulfilment by Ebazaar (FBE) service. FBE offers a comprehensive solution that handles all your shipping needs under one roof. With FBE, you can entrust Ebazaar to efficiently manage the entire process, from storing your products to expertly packing and shipping them to customers. Enjoy a hassle-free experience and focus on growing your business while Ebazaar takes care of the logistics.</p>

                  <ul>
                    <li>Pick-up from seller location to Ebazaar warehouse</li>
                    <li>FAssured badge</li>
                    <li>Faster delivery to customer</li>
                    <li>Seamless order processing</li>
                    <li>Customer returns handled end to end</li>
                    <li>Warehouse space</li>
                    <li>Quality selection recommendation</li>
                    <li>Rigorous quality checks</li>
                    <li>Quality packaging materials</li>
                  </ul>

                  <h5 className='headline-seller2'>Non Fulfilment by Ebazaar (NFBF)</h5>
                  <p>With Non-Fulfillment by Ebazaar (NFBF), you can benefit from end-to-end delivery of your products directly from your location to the customer. In NFBF, it is the responsibility of the seller to ensure that the product is properly packed and ready for dispatch before the Ebazaar agent arrives at the seller's location. This service allows you to maintain control over the packaging process while leveraging Ebazaar's logistics network for efficient and reliable delivery.</p>
                  <ul>
                    <li>Delivery to 19000+ pin codes across India</li>
                    <li>Tracking of your product</li>
                    <li>Customer returns support</li>
                    <li>Logistics support from community warehouse available</li>
                  </ul>


                  <table className='tab1' border="1" cellspacing="0" cellpadding="10">
                    <thead>
                      <tr>
                        <th>Feature</th>
                        <th>Fulfilment by Ebazaar (FBE)</th>
                        <th>Non Fulfilment by Ebazaar (NFBE)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Packaging</td>
                        <td>Ebazaar will pack your products</td>
                        <td>Seller will pack their products before pick-up</td>
                      </tr>
                      <tr>
                        <td>Storage</td>
                        <td>Ebazaar will store your products at the nearest Ebazaar warehouse</td>
                        <td>Seller will store products at their premises or community warehouse</td>
                      </tr>
                      <tr>
                        <td>Shipping</td>
                        <td>Ebazaar will pick and deliver your products to the customer</td>
                        <td>Seller will schedule a pick-up & an Ebazaar agent will pick your order</td>
                      </tr>
                      <tr>
                        <td>Fees</td>
                        <td>Relevant FBE fees will be applicable</td>
                        <td>Normal shipping cost will be applicable to help deliver your product to your customer, based on local, zonal, or national delivery</td>
                      </tr>
                      <tr>
                        <td>Returns</td>
                        <td>Managed by Ebazaar</td>
                        <td>Managed by Ebazaar</td>
                      </tr>
                      <tr>
                        <td>Customer Service</td>
                        <td>Managed by Ebazaar</td>
                        <td>Managed by Ebazaar</td>
                      </tr>
                      <tr>
                        <td>FAssured Badge</td>
                        <td>Yes</td>
                        <td>FAssured badge to be provided based on reliability score</td>
                      </tr>
                    </tbody>
                  </table>

                  <div className='mt-3'>
                    <h5>FAssured by Ebazaar: Your Badge of Reliability</h5>
                    <p>The <strong>FAssured by Ebazaar</strong> program is an exclusive reliability initiative designed to provide enhanced visibility and added benefits to your products. When your products participate in FAssured, they undergo rigorous quality checks and are guaranteed to reach customers within 2-4 days of ordering.</p>

                    <ul>
                      <li><strong>Faster Delivery</strong>: A commitment to delivering within 2-4 days, meeting customer expectations for prompt service.</li>
                      <li><strong>Increased Visibility</strong>: FAssured products receive higher prominence on Ebazaar, helping them stand out to potential buyers.</li>
                      <li><strong>Enhanced Trust</strong>: As a mark of reliability, the FAssured badge signifies quality and speed, establishing trust with customers.</li>
                      <li><strong>Higher Sales Potential</strong>: With better visibility and faster delivery, FAssured products often experience increased orders, ultimately boosting revenue.</li>
                    </ul>

                    <p>The FAssured badge serves as Ebazaar's stamp of trust, ensuring customers recognize your products for their reliability, quality, and speed. Gaining this badge not only enhances your reputation on Ebazaar but also offers a more rewarding online selling experience.</p>
                  </div>


                </div>

              </div>
              <div id="receivePayments" className="content-section">
                <h2>Receive Payments</h2>
                <p>With a customer base of over 45 crore+ on Ebazaar, you can expect orders from customers across India. For your convenience, Ebazaar ensures timely payments directly into your registered bank account, which you provided during the account creation process. You can receive your payments as quickly as 7 days* from the date of dispatch, enabling you to manage your cash flow efficiently and focus on growing your business.
                </p>
              </div>
              <div id="growFaster" className="content-section">
                <h2>Grow Faster</h2>
                <p>
                  At Ebazaar, we recognize that there may be times when you require additional assistance for your online business. That's why, with your Ebazaar seller account, you gain access to a diverse range of tools and support functions designed to foster business growth. These include:
                </p>
                <ul>
                  <li><strong>Price Recommendation Tool:</strong> Helps you determine optimal pricing for your products.</li>
                  <li><strong>Product Recommendation Tool:</strong> Suggests popular and trending products to expand your product selection.</li>
                  <li><strong>Ebazaar Ads:</strong> Enables you to advertise your products and reach a larger customer base.</li>
                  <li><strong>Paid Account Management Services:</strong> Offers dedicated account management support for personalized guidance.</li>
                  <li><strong>Catalog & Photoshoot Services:</strong> Assists with creating high-quality product catalogs and images.</li>
                  <li><strong>Shopping Festivals and More:</strong> Participate in exciting sales events and promotional campaigns.</li>
                </ul>
                <p><a href="#">Know more on how to grow your business</a></p>

              </div>
              <div id="sellerApp" className="content-section">
                <h2>Seller App</h2>
                <p>
                  What sets us apart is our exceptional **Ebazaar seller support** assistance. We prioritize your needs and are committed to providing you with prompt assistance, whether you have questions, doubts, or require any kind of support for your business. Our dedicated team is here to help you every step of the way, ensuring that you have a smooth and successful selling experience on **Ebazaar**. Feel free to reach out to us whenever you need assistance – we're always here to support you.
                </p>
                <p><a href="#">Know more about the assistance we offer.</a></p>
                <p><a href="#">A step-by-step guide to help with your Ebazaar account creation can be downloaded from here.</a></p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BecomeSeller;

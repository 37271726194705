import React from 'react';
import './Whishlist.css'
// Sample Wishlist Products Array
const wishlistProducts = [
  {
    id: 1,
    name: "Wireless Bluetooth Earbuds",
    image: "https://rukminim2.flixcart.com/image/612/612/xif0q/sandal/o/i/e/8-411-heighten-white-original-imagquk58ya3vvqm.jpeg?q=70",
    price: 2999,
    rating: 4.5,
    availability: "In Stock",
  },
  {
    id: 2,
    name: "Smart Fitness Watch",
    image: "https://rukminim2.flixcart.com/image/612/612/xif0q/sandal/o/i/e/8-411-heighten-white-original-imagquk58ya3vvqm.jpeg?q=70",
    price: 4999,
    rating: 4.2,
    availability: "Out of Stock",
  },
  {
    id: 3,
    name: "Noise Cancelling Headphones",
    image: "https://rukminim2.flixcart.com/image/612/612/xif0q/sandal/o/i/e/8-411-heighten-white-original-imagquk58ya3vvqm.jpeg?q=70",
    price: 8999,
    rating: 4.7,
    availability: "In Stock",
  },
  {
    id: 4,
    name: "Portable Power Bank",
    image: "https://rukminim2.flixcart.com/image/612/612/xif0q/sandal/o/i/e/8-411-heighten-white-original-imagquk58ya3vvqm.jpeg?q=70",
    price: 1999,
    rating: 4.3,
    availability: "In Stock",
  },
  {
    id: 5,
    name: "Smartphone Gimbal Stabilizer",
    image: "https://rukminim2.flixcart.com/image/612/612/xif0q/sandal/o/i/e/8-411-heighten-white-original-imagquk58ya3vvqm.jpeg?q=70",
    price: 6999,
    rating: 4.6,
    availability: "Out of Stock",
  },
];

const WishlistPage = () => {
  return (
    <div className="container whishlist padding-20-15 white-section section-margin-top section-margin-bottom product-list-containet">
           <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">My Wishlist</li>
                                </ol>
                            </nav>
      <div className="row">
        {wishlistProducts.map((product) => (
          <div key={product.id} className="col-6 col-lg-3 mb-4">
            <div className="card h-100">
              <img src={product.image} alt={product.name} className="card-img-top" />
              <div className="card-body">
                <h5 className="card-title">{product.name}</h5>
                <p className="card-text">Price: ₹{product.price}</p>
                <div className='d-flex justify-content-between align-items-center'>
                <p className="card-text">Rating: ⭐{product.rating}</p>
                <p className={`card-text ${product.availability === 'In Stock' ? 'text-success' : 'text-danger'}`}>
                  {product.availability}
                </p>
                </div>
                 <div className='card-btn'>
                 <button className="btn btn-primary" disabled={product.availability === "Out of Stock"}>
                  Add to Cart
                </button>
                <button className="btn btn-outline-danger">Remove</button>
                 </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WishlistPage;

import React, { useState } from 'react';
import './Nav.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import MobileNavbar from './MobileNavbar';

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  // List of paths where the search bar should not be displayed
  const hideSearchBarRoutes = [
    '/contact',
    '/about',
    '/cart',
    '/privacy',
    '/shipping',
    '/faq',
    '/security',
    '/terms',
    '/becomeseller' // Become a seller route bhi yahan add kiya hai
  ];

  // Routes jahan pura navbar chhupana hai
  const hideNavbarRoutes = ['/becomeseller'];

  // Determine if navbar should be hidden
  const isNavbarHidden = hideNavbarRoutes.includes(location.pathname);
  if (isNavbarHidden) {
    return null; // If the navbar is hidden, return null
  }

  // Conditional class based on route
  const navbarClass = location.pathname === '/' ? 'navbar-white' : 'navbar-blue';

  const handleMenuItemClick = (event, path) => {
    event.preventDefault(); // Prevent default anchor behavior
    navigate(path); // Perform routing logic
    setIsOpen(false); // Close the dropdown after clicking a menu item
  };

  return (
    <>
      <nav className={`navbar navbar-expand-lg ${navbarClass} ${hideSearchBarRoutes.includes(location.pathname) ? 'no-search' : ''}`}>
        <div className='mobile-left-menu'>
          <MobileNavbar/>
          <Link className="navbar-brand" to="/">Ebazaar</Link>
        </div>
        {!hideSearchBarRoutes.includes(location.pathname) && (
          <form className="d-flex has-search w-100 d-lg-block d-none">
            <span className="fa fa-search form-control-feedback"></span>
            <input className="form-control me-2" type="search" placeholder="Search for Products, Brands and More" aria-label="Search" />
          </form>
        )}

        <ul className="nav nav-right">
          <li className="nav-item hover-item">
            <div
              className="dropdown dropAnimation"
              onMouseEnter={() => setIsOpen(true)} // Show on hover
              onMouseLeave={() => setIsOpen(false)} // Hide on mouse leave
              style={{ cursor: 'pointer' }} // Cursor change for the button
            >
              <div id="dropdownMenuButton1" aria-expanded={isOpen}>
                <i className="fa-regular fa-circle-user"></i> Account
              </div>
              {isOpen && (
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <div className='drop-header'>
                    <div className='login-link'><Link className='link' to='/register'>New Customer?</Link></div>
                    <div className='register-link'><Link className='link' to='/login'>Sign Up</Link></div>
                  </div>
                  <li>
                    <a className="dropdown-item" href="#" onClick={(e) => handleMenuItemClick(e, '/account')}>
                      <i className="fa-regular fa-circle-user"></i> My Profile
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#" onClick={(e) => handleMenuItemClick(e, '/orderPage')}>
                      <i className="fa-solid fa-cart-plus"></i> Orders
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#" onClick={(e) => handleMenuItemClick(e, '/whishlist')}>
                      <i className="fa-regular fa-heart"></i> Wishlist
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#" onClick={(e) => handleMenuItemClick(e, '/gift_card')}>
                      <i className="fa-regular fa-credit-card"></i> Gift Cards
                    </a>
                  </li>
                </ul>
              )}
            </div>
          </li>
          <li className="nav-item cart-box">
            <div className='cart-add'>2</div>
            <div className="dropdown mid-cart cart" onClick={() => navigate('/cart')}>
              <div className="d-flex align-items-center" >
                <i className="fa-solid fa-cart-shopping me-1"></i> <span className='d-none d-lg-block'> Cart</span>
              </div>
            </div>
          </li>
          <li className="nav-item d-flex align-items-center d-lg-block d-none" onClick={() => navigate('/becomeseller')}>
            <i className="fa-solid fa-box-archive"></i> <span >Become a seller</span>
          </li>
          {!hideSearchBarRoutes.includes(location.pathname) && (
            <li className="nav-item d-lg-block d-none">
              <div className="veritcal-menu">
                <div className="btn-group dropstart verMenuAnimation">
                  <button type="button" className="btn" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" onClick={(e) => handleMenuItemClick(e, '/notification')}>
                        <i className="fa-regular fa-bell"></i> Notification Preferences
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" onClick={(e) => handleMenuItemClick(e, '/cusomer_support')}>
                        <i className="fa-solid fa-headset"></i> 27x7 Customer Care
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        <i className="fa-solid fa-download"></i> Download App
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          )}
        </ul>
      </nav>

      <div className='container-fluid'>
        <form className="d-flex has-search w-100 d-block d-lg-none">
          <span className="fa fa-search form-control-feedback"></span>
          <input className="form-control me-2" type="search" placeholder="Search for Products, Brands and More" aria-label="Search" />
        </form>
      </div>
    </>
  );
}

export default Navbar;

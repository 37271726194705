import React from 'react'
import './Featured_Brands.css'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const options = {
    stagePadding: 50, // 4th item ka partial view
    responsive: {
      0: {
        items: 2, // Small screen mein 2 cards
      },
      768: {
        items: 3, // Larger screen mein 6 cards
      },
    },
    nav: true, // Show next and prev buttons
    dots: false, // Show dots navigation
  };


  const cardData = [
    {
      img: "https://rukminim2.flixcart.com/fk-p-flap/450/280/image/af6449de36271e18.jpeg?q=20",
    },
    {
      img: "https://rukminim2.flixcart.com/fk-p-flap/450/280/image/fa8a38249af315e5.jpeg?q=20",
    },
    {
      img: "https://rukminim2.flixcart.com/fk-p-flap/450/280/image/759c72df8ca07c2e.jpeg?q=20",
    },
    {
      img: "https://rukminim2.flixcart.com/fk-p-flap/450/280/image/79d88d545c9be7c8.jpeg?q=20",
    },
  ];



function Featured_Brands() {
  return (
    <>
        <div>
<div className='container-fluid'>
<div className='white-section'>
<div className='d-flex justify-content-between align-items-center'>
<h4 className='headline'>Featured Brand</h4>
<i class="fas fa-chevron-right right-chev"></i>
</div>
<OwlCarousel className="owl-theme" {...options}>
  {cardData.map((card, index) => (
    <div className="item" key={index} >
      <div className="card text-start" >
        <div className='img-box'>
          <img className="card-img-top" src={card.img} alt={card.title} />
        </div>
      </div>
    </div>
  ))}
</OwlCarousel>
</div>

</div>
</div>
    </>
  )
}

export default Featured_Brands;
import React from 'react';
import './UserSupport.css'

const CustomerSupportPage = () => {
  return (
    <div className="container userSupport section-margin-top-bottom white-section padding-20-15">
      <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">24x7 Customer Care Support</li>
                                </ol>
                            </nav>

      {/* Contact Information */}
      <div className="card mb-4">
        <div className="card-header bg-primary text-white">
          Contact Information
        </div>
        <div className="card-body">
          <p>For any queries, feel free to reach us 24/7 through:</p>
          <ul>
            <li><strong>Phone:</strong> +1 234 567 890</li>
            <li><strong>Email:</strong> support@ecommerce.com</li>
            <li><strong>Live Chat:</strong> Available on our website</li>
          </ul>
          <p>We’re here to help you anytime, anywhere!</p>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="card mb-4">
        <div className="card-header bg-success text-white">
          Frequently Asked Questions (FAQs)
        </div>
        <div className="card-body">
          <h5>How can I track my order?</h5>
          <p>You can track your order by visiting the ‘My Orders’ section in your account.</p>
          
          <h5>What is the return policy?</h5>
          <p>We offer a 30-day return policy for most items. Please check our return policy page for more details.</p>

          <h5>How can I cancel my order?</h5>
          <p>You can cancel your order before it is shipped by visiting the ‘My Orders’ section and selecting ‘Cancel’.</p>
        </div>
      </div>

      {/* Contact Form */}
      <div className="card">
        <div className="card-header bg-info text-white">
          Get in Touch with Us
        </div>
        <div className="card-body">
          <form>
            <div className="form-group mb-4">
              <label htmlFor="name">Name</label>
              <input type="text" className="form-control" id="name" placeholder="Enter your name" />
            </div>
            <div className="form-group mb-4">
              <label htmlFor="email">Email address</label>
              <input type="email" className="form-control" id="email" placeholder="Enter your email" />
            </div>
            <div className="form-group mb-4">
              <label htmlFor="message">Message</label>
              <textarea className="form-control" id="message" rows="3" placeholder="How can we assist you?"></textarea>
            </div>
            <button type="submit" className="btn btn-primary mb-2">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CustomerSupportPage;

import React from 'react';
import './Footer.css'
import { Link } from 'react-router-dom';

function Footer() {
  return (

    <div>
      <footer className="footer container-fluid">
        <div className="footer-section row">
          <div className='col-md-7 left-footer'>
            {/* About Section */}
            <div className="footer-links ">
              <h3>ABOUT</h3>
              <ul>
                <li><Link to="/contact">Contact Us</Link></li>
                <li><Link to="/about">About Us</Link></li>
                <li><a href="#">Careers</a></li>
                <li><a href="#">Flipkart Stories</a></li>
                <li><a href="#">Press</a></li>
                <li><a href="#">Flipkart Wholesale</a></li>
                <li><a href="#">Corporate Information</a></li>
              </ul>
            </div>

            {/* Help Section */}
            <div className="footer-links">
              <h3>HELP</h3>
              <ul>
                <li><Link to="/payment">Payments</Link></li>
                <li><Link to="/shipping">Shipping</Link></li>
                <li><Link to="/cancellation">Cancellation & Returns</Link></li>
                <li><Link to="/faq">FAQ</Link></li>
                <li><a href="#">Report Infringement</a></li>
              </ul>
            </div>

            {/* Policy Section */}
            <div className="footer-links">
              <h3>POLICY</h3>
              <ul>
                <li><a href="#">Return Policy</a></li>
                <li><Link to="/terms">Terms of Use</Link></li>
                <li><Link to="/security">Security</Link></li>
                 <li><Link to="/privacy">Privacy</Link></li>
                {/* <li><a href="#">Sitemap</a></li> */}
                <li><Link to="/epr-compliance">EPR Compliance</Link></li>
              </ul>
            </div>

            {/* Social Media Section */}
            <div className="footer-links ">
              <h3>SOCIAL</h3>
              <ul>
                <li><a href="#">Facebook</a></li>
                <li><a href="#">Twitter</a></li>
                <li><a href="#">YouTube</a></li>
              </ul>
            </div>
          </div>
          <div className='col-md-5 right-side-border'>
            <div className='row'>
              <div className='col-md-6'>
                <div className="footer-links ">
                  <h3>Mail Us</h3>
                  <ul>
                    <li>
                      <p className='text-light'>EBAZAAR's email service is here to assist you. Feel free to reach out for any questions or concerns. Your feedback and suggestions are important to us.</p>
                    </li>
                    <li className='text-light'>Telephone: <a>+91 99009 99990</a></li>
                    <li className='text-light'>Mail: <a>ebazaaeco@gmail.com</a></li>
                  </ul>
                </div>
              </div>
              <div className='col-md-6'>
                <div className="footer-links ">
                  <h3>PAYMENT METHODS</h3>
                    <div className='payment-methods'>
                      <div className=''>
                        <img className='img-fluid' src='https://cdn4.iconfinder.com/data/icons/payment-method/160/payment_method_card_visa-512.png' />
                      </div>
                      <div className=''>
                        <img className='img-fluid' src='https://www.svgrepo.com/show/328141/mastercard.svg' />
                      </div>
                      <div className=''>
                        <img className='img-fluid' src='https://cdn.iconscout.com/icon/free/png-256/free-paypal-logo-icon-download-in-svg-png-gif-file-formats--online-payment-logos-pack-icons-226456.png?f=webp&w=256' />
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer Legal Info */}
        <div className="footer-legal">
          <p>© 2024 Ebazaar. All rights reserved.</p>
        </div>
      </footer>
    </div>
  )
}

export default Footer
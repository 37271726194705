// import React from 'react';
import React, { useState } from 'react';
import './ProductList.css'
import ProductFilter from '../ProductFilter/ProductFilter';
import { useNavigate } from 'react-router-dom';
import HoverDropdown from '../HoverDropdown/HoverDropdown';
import AppFilter from '../AppFilter/AppFilter';
// import MegaMenu from '../MegaMenu/MegaMenu';

// import products from './products';


const products = [
    {
        id: 1,
        brand: 'Red Tape',
        name: 'Sneaker Casual Shoes for Men',
        description: 'Soft Cushioned Insole, Stylish Design',
        price: 985,
        originalPrice: 5799,
        discount: '83% off',
        sizes: ['6', '8', '9'],
        popularity: 5, // Higher number means more popular
        addedDate: '2024-10-10', // Date when added for sorting by newest
        img: 'https://rukminim2.flixcart.com/image/612/612/xif0q/shoe/k/m/o/11-22251838-45-hrx-by-hrithik-roshan-navy-blue-original-imah5fdrerk9czxd.jpeg?q=70'
    },
    {
        id: 2,
        brand: 'Red Tape',
        name: 'Sneaker Casual Shoes for Men',
        description: 'Soft Cushioned Insole, Comfortable Fit',
        price: 985,
        originalPrice: 5799,
        discount: '83% off',
        sizes: ['6', '8', '9'],
        popularity: 4,
        addedDate: '2024-10-12',
        img: 'https://rukminim2.flixcart.com/image/612/612/xif0q/shoe/7/i/j/6-29825844-40-hrx-by-hrithik-roshan-black-original-imah5fdr8uurfzsu.jpeg?q=70'
    },
    {
        id: 3,
        brand: 'Red Tape',
        name: 'Stylish Casual Sneakers',
        description: 'Lightweight and Durable',
        price: 1200,
        originalPrice: 4999,
        discount: '76% off',
        sizes: ['7', '8', '10'],
        popularity: 3,
        addedDate: '2024-10-11',
        img: 'https://rukminim2.flixcart.com/image/612/612/xif0q/shoe/l/h/w/9-29825832-43-hrx-by-hrithik-roshan-black-original-imah5fdrbbg8wgwv.jpeg?q=70'
    },
    {
        id: 4,
        brand: 'Red Tape',
        name: 'Classic Black Sneakers',
        description: 'Perfect for Everyday Wear',
        price: 1350,
        originalPrice: 5000,
        discount: '73% off',
        sizes: ['6', '8', '9', '10'],
        popularity: 2,
        addedDate: '2024-10-09',
        img: 'https://rukminim2.flixcart.com/image/612/612/xif0q/shoe/6/o/g/7-29825830-41-hrx-by-hrithik-roshan-beige-original-imah5fdryqagqhyc.jpeg?q=70'
    },
    {
        id: 5,
        brand: 'Red Tape',
        name: 'Sporty Running Shoes',
        description: 'Breathable Material for Comfort',
        price: 1500,
        originalPrice: 6000,
        discount: '75% off',
        sizes: ['6', '7', '8', '9'],
        popularity: 1,
        addedDate: '2024-10-14',
        img: 'https://rukminim2.flixcart.com/image/612/612/xif0q/shoe/6/o/g/7-29825830-41-hrx-by-hrithik-roshan-beige-original-imah5fdryqagqhyc.jpeg?q=70'
    },
    {
        id: 6,
        brand: 'Red Tape',
        name: 'High-Top Casual Sneakers',
        description: 'Great Ankle Support',
        price: 1800,
        originalPrice: 7200,
        discount: '75% off',
        sizes: ['8', '9', '10'],
        popularity: 2,
        addedDate: '2024-10-08',
        img: 'https://rukminim2.flixcart.com/image/612/612/xif0q/shoe/6/o/g/7-29825830-41-hrx-by-hrithik-roshan-beige-original-imah5fdryqagqhyc.jpeg?q=70'
    },
    {
        id: 7,
        brand: 'Red Tape',
        name: 'Fashionable Slip-On Shoes',
        description: 'Easy to Wear and Stylish',
        price: 1200,
        originalPrice: 4500,
        discount: '73% off',
        sizes: ['7', '8', '9'],
        popularity: 4,
        addedDate: '2024-10-06',
        img: 'https://rukminim2.flixcart.com/image/612/612/xif0q/shoe/6/o/g/7-29825830-41-hrx-by-hrithik-roshan-beige-original-imah5fdryqagqhyc.jpeg?q=70'
    },
    {
        id: 8,
        brand: 'Red Tape',
        name: 'Trendy Outdoor Shoes',
        description: 'Durable and Comfortable',
        price: 1500,
        originalPrice: 5500,
        discount: '73% off',
        sizes: ['6', '8', '9', '10'],
        popularity: 5,
        addedDate: '2024-10-07',
        img: 'https://rukminim2.flixcart.com/image/612/612/xif0q/shoe/6/o/g/7-29825830-41-hrx-by-hrithik-roshan-beige-original-imah5fdryqagqhyc.jpeg?q=70'
    },
];
const ProductList = () => {
    const navigate = useNavigate();

    const handleCardClick = () => {
        navigate('/productbuy')
    }
    const [sortedProducts, setSortedProducts] = useState(products);
    const [activeSort, setActiveSort] = useState('popularity');

    const handleSort = (sortType) => {
        setActiveSort(sortType);
        let sortedArray = [...products];

        switch (sortType) {
            case 'popularity':
                sortedArray.sort((a, b) => b.popularity - a.popularity);
                break;
            case 'priceLowToHigh':
                sortedArray.sort((a, b) => a.price - b.price);
                break;
            case 'priceHighToLow':
                sortedArray.sort((a, b) => b.price - a.price);
                break;
            case 'newest':
                sortedArray.sort((a, b) => new Date(b.addedDate) - new Date(a.addedDate));
                break;
            default:
                break;
        }

        setSortedProducts(sortedArray);
    };

    return (
        <>
           <AppFilter/>
            {/* <MegaMenu/> */}
            <HoverDropdown />
            <div className='container-fluid section-margin-top section-margin-bottom product-list-containet'>

                <div className='row g-0 '>
                    <div className='col-md-2  d-none d-lg-block'>
                        <div className='col-right-space'>
                        <ProductFilter />
                        </div>
                    </div>
                    <div className='col-md-10 white-section padding-20-5'>
                        <div className='container-padding '>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                                    <li class="breadcrumb-item"><a href="#">Footwear</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Men's Footwear</li>
                                </ol>
                            </nav>
                            <div>
                                <p className='small-long-deatails'>When it comes to navigating the streets, protecting your feet is paramount. Choosing the right footwear is essential for your daily adventures. At EBAZAAR, you'll discover an extensive selection of shoes available at your fingertips. With just a few clicks, explore a range of sneakers designed with the utmost comfort in mind, offering excellent cushioning for your feet.
                                    Opt for shoes that provide effective shock absorption to minimize the risk of bruising during your activities. Don't forget to check out our collection of cozy socks that complement these footwear options perfectly.
                                    If you're looking to elevate your style with a business casual flair, consider our loafers that pair effortlessly with your favorite T-shirt and pants combo. For a more laid-back vibe, our sandals are perfect for everyday wear, ensuring comfort without compromising on style.
                                    With a diverse array of brands such as Footup, Svaar, Pennen, Bata, PUMA, and more, you'll be spoiled for choice while shopping for footwear online. Bring home the perfect pair of shoes from EBAZAAR and enjoy a blend of style and comfort.Price List last updated on 15-Oct-24.</p>

                            </div>
                            <div className='products-area'>
                                <div className='custom-flex'><h5 className='product-name h5'>Men's Footwear</h5>
                                    <span className='products-showing'>(Showing 1 – 40 products of 3,082 products)</span></div>
                            </div>
                            <div >

                                <div className='product-btn-list'>
                                    <h2>Sort By</h2>
                                    <button
                                        onClick={() => handleSort('popularity')}
                                        className={`sort-button ${activeSort === 'popularity' ? 'active' : ''}`}
                                    >
                                        Popularity
                                    </button>
                                    <button
                                        onClick={() => handleSort('priceLowToHigh')}
                                        className={`sort-button ${activeSort === 'priceLowToHigh' ? 'active' : ''}`}
                                    >
                                        Price -- Low to High
                                    </button>
                                    <button
                                        onClick={() => handleSort('priceHighToLow')}
                                        className={`sort-button ${activeSort === 'priceHighToLow' ? 'active' : ''}`}
                                    >
                                        Price -- High to Low
                                    </button>
                                    <button
                                        onClick={() => handleSort('newest')}
                                        className={`sort-button ${activeSort === 'newest' ? 'active' : ''}`}
                                    >
                                        Newest First
                                    </button>
                                </div>

                                {/* <h2>Products</h2> */}
                                <div className='row'>
                                    {sortedProducts.map((product) => (
                                        <div className='col-6 custom-padding col-lg-3 mb-4' key={product.id}>
                                            <div className='card' onClick={handleCardClick}>
                                                <i class="fa-solid fa-heart"></i>
                                                <img
                                                    className='product-img img-fluid'
                                                    src={product.img}
                                                    alt={`${product.brand} ${product.name}`}
                                                />
                                                <div className='card-body'>
                                                    {/* <h3 className='products-brand'>{product.brand}</h3> */}
                                                    <p className='product-title'>{product.name}</p>
                                                    <p className='product-disc'>{product.description}</p>
                                                    <p>
                                                        Price: ₹{product.price} <span className='orgprice'>₹{product.originalPrice}</span> <span className='discount'>({product.discount})</span>
                                                    </p>
                                                    <p className='size'>Sizes: {product.sizes.join(', ')}</p>
                                                </div>
                                            </div>

                                        </div>
                                    ))}
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>


    );
};

export default ProductList;

import React from 'react';
import './Pages.css';

const PrivacyPolicy = () => {
    return (
        <>
               <div className='container'>
            <div className=" bg-white pages-conatiner">
                <div className='content'>
                    <h3>Privacy Policy</h3>
                    <p>
                        Your privacy is important to us. EBAZAAR is committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data.
                    </p>
                    <p><strong>Information Collection:</strong></p>
                    <p>
                        We collect personal information when you create an account, place an order, or interact with our website. This may include your name, email address, phone number, and shipping address.
                    </p>
                    <p><strong>Use of Information:</strong></p>
                    <p>
                        Your information is used to process orders, communicate with you about your account or orders, and improve our services. We may also use your data to send promotional offers, if you opt-in for marketing communications.
                    </p>
                    <p><strong>Data Security:</strong></p>
                    <p>
                        We implement a variety of security measures to maintain the safety of your personal information. All sensitive data is transmitted via Secure Socket Layer (SSL) technology.
                    </p>
                    <p><strong>Sharing of Information:</strong></p>
                    <p>
                        We do not sell or trade your personal information to outside parties. We may share your data with trusted third parties who assist us in operating our website, conducting our business, or servicing you, as long as those parties agree to keep this information confidential.
                    </p>
                    <p><strong>Your Rights:</strong></p>
                    <p>
                        You have the right to access, correct, or delete your personal information at any time. If you wish to exercise these rights, please contact us through our customer support team.
                    </p>
                    <p>
                        By using our site, you consent to our Privacy Policy. We may update this policy from time to time, and we encourage you to review it periodically.
                    </p>
                </div>
            </div>
        </div>
        </>
    );
};

export default PrivacyPolicy;

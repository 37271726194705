import React from 'react';
import './UserSupport.css'

const NotificationPage = () => {
  return (
    <div className="container my-4 userSupport section-margin-top-bottom white-section padding-20-15">
         <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Notification</li>
                                </ol>
                            </nav>

      {/* Order Updates */}
      <div className="card mb-3">
        <div className="card-header d-flex justify-content-between">
          <span>Order Updates</span>
          <span className="badge badge-info">New</span>
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">Your order #1234 has been shipped!</li>
          <li className="list-group-item">Your order #1235 is out for delivery.</li>
          <li className="list-group-item">Your order #1236 has been delivered. Please leave a review!</li>
        </ul>
      </div>

      {/* Promotional Notifications */}
      <div className="card mb-3">
        <div className="card-header d-flex justify-content-between">
          <span>Promotional Offers</span>
          <span className="badge badge-success">Hot</span>
        </div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">Get 20% off on your next purchase! Use code SAVE20.</li>
          <li className="list-group-item">Flash Sale: Up to 50% off on electronics!</li>
          <li className="list-group-item">New arrivals in your favorite categories!</li>
        </ul>
      </div>

      {/* Price Drop Alerts */}
      <div className="card mb-3">
        <div className="card-header">Price Drop Alerts</div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">Item in your wishlist is now $10 cheaper!</li>
          <li className="list-group-item">Price drop alert: Smartphone now at a discounted price!</li>
        </ul>
      </div>

      {/* Product Availability Alerts */}
      <div className="card mb-3">
        <div className="card-header">Product Availability</div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">Item "Wireless Earbuds" is back in stock!</li>
          <li className="list-group-item">Limited stock alert for "Smart Watch". Hurry up!</li>
        </ul>
      </div>

      {/* Account-Related Notifications */}
      <div className="card mb-3">
        <div className="card-header">Account Alerts</div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">Your password was changed successfully.</li>
          <li className="list-group-item">New login from a different location.</li>
        </ul>
      </div>

      {/* Payment & Refund Notifications */}
      <div className="card mb-3">
        <div className="card-header">Payment & Refunds</div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">Payment for order #1234 confirmed.</li>
          <li className="list-group-item">Refund for order #1235 has been processed.</li>
        </ul>
      </div>

      {/* Customer Service Notifications */}
      <div className="card mb-3">
        <div className="card-header">Customer Service</div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">Your support ticket #5678 has been updated.</li>
          <li className="list-group-item">Customer support responded to your query.</li>
        </ul>
      </div>
    </div>
  );
};

export default NotificationPage;

import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';


function Address() {
  const [open, setOpen] = useState(false);
  const [addressDetails, setAddressDetails] = useState({
      name: '',
      mobile: '',
      pincode: '',
      locality: '',
      address: '',
      city: '',
      state: '',
      landmark: '',
      alternatePhone: '',
      addressType: ''
  });

  const handleClickOpen = () => {
      setOpen(true);  // Dialog ko open karne ka function
  };

  const handleClose = () => {
      setOpen(false); // Dialog ko close karne ka function
  };

  const handleSave = () => {
      console.log("Address saved:", addressDetails);
      handleClose(); // Save karne par dialog close
  };

  const handleChange = (e) => {
      const { name, value } = e.target;
      setAddressDetails({
          ...addressDetails,
          [name]: value // Form fields ka value set karne ke liye
      });
  };

  return (
    <div>
        <div className='mb-4'><Typography variant="h6">Your Address</Typography></div>
      <Typography variant="body1">{addressDetails.address || "No address provided"}</Typography>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Edit
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Address</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            type="text"
            fullWidth
            variant="outlined"
            name="name"
            value={addressDetails.name}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            label="10-digit Mobile Number"
            type="tel"
            fullWidth
            variant="outlined"
            name="mobile"
            value={addressDetails.mobile}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            label="Pincode"
            type="text"
            fullWidth
            variant="outlined"
            name="pincode"
            value={addressDetails.pincode}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            label="Locality"
            type="text"
            fullWidth
            variant="outlined"
            name="locality"
            value={addressDetails.locality}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            label="Address (Area and Street)"
            type="text"
            fullWidth
            variant="outlined"
            name="address"
            value={addressDetails.address}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            label="City/District/Town"
            type="text"
            fullWidth
            variant="outlined"
            name="city"
            value={addressDetails.city}
            onChange={handleChange}
            margin="normal"
          />
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>Select State</InputLabel>
            <Select
              label="Select State"
              name="state"
              value={addressDetails.state}
              onChange={handleChange}
            >
              <MenuItem value=""><em>--Select State--</em></MenuItem>
              <MenuItem value="Maharashtra">Maharashtra</MenuItem>
              <MenuItem value="Karnataka">Karnataka</MenuItem>
              {/* Add more states as needed */}
            </Select>
          </FormControl>
          <TextField
            label="Landmark (Optional)"
            type="text"
            fullWidth
            variant="outlined"
            name="landmark"
            value={addressDetails.landmark}
            onChange={handleChange}
            margin="normal"
          />
          <TextField
            label="Alternate Phone (Optional)"
            type="tel"
            fullWidth
            variant="outlined"
            name="alternatePhone"
            value={addressDetails.alternatePhone}
            onChange={handleChange}
            margin="normal"
          />
          <Typography variant="subtitle1">Address Type</Typography>
          <RadioGroup
            name="addressType"
            value={addressDetails.addressType}
            onChange={handleChange}
          >
            <FormControlLabel value="Home" control={<Radio />} label="Home" />
            <FormControlLabel value="Work" control={<Radio />} label="Work" />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Address
import React, { useState } from 'react';
import './Contact.css';
import { Link } from 'react-router-dom';
import { TextField, Button, Typography, Paper, Box, Grid } from '@mui/material';

const ContactPage = () => {
    const [showMap, setShowMap] = useState(false);

    const handleShowMap = () => {
        setShowMap(true);
    };

    return (
        <>
            <div className='contact-page container  section-margin-top-bottom '>
                <Box >
                    <Paper elevation={3} sx={{ padding: 3 }}>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item"><a href="#">Contact</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Contact us</li>
                            </ol>
                        </nav>
                        <Typography variant="body1" gutterBottom>
                            If you need any assistance, please don't hesitate to contact us. We are here to help you!
                        </Typography>

                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <form>
                                    <TextField
                                        fullWidth
                                        label="Name"
                                        variant="outlined"
                                        margin="normal"
                                        required
                                    />
                                    <TextField
                                        fullWidth
                                        label="Email"
                                        type="email"
                                        variant="outlined"
                                        margin="normal"
                                        required
                                    />
                                    <TextField
                                        fullWidth
                                        label="Phone (optional)"
                                        type="tel"
                                        variant="outlined"
                                        margin="normal"
                                    />
                                    <TextField
                                        fullWidth
                                        label="Message"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        margin="normal"
                                        required
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        sx={{ marginTop: 2 }}
                                    >
                                        Send Message
                                    </Button>
                                </form>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <div className='contact-right'>
                                    <Typography variant="h6" sx={{ marginTop: 2 }}>
                                        Contact Information
                                    </Typography>
                                    <ul>
                                        <li><a>Email: support@ebazaar.com</a></li>
                                        <li>Phone: +91-XXXXXXXXXX</li>
                                        <li>Address: Sector 48 Near Rahul School, Noida Uttar Pradesh.</li>
                                    </ul>

                                    <Typography variant="h6" sx={{ marginTop: 4 }}>
                                        Follow Us
                                    </Typography>
                                    <Typography className='social-links' variant="body1">
                                        <a href="https://facebook.com">Facebook</a> | 
                                        <a href="https://instagram.com"> Instagram</a> | 
                                        <a href="https://twitter.com"> Twitter</a>
                                    </Typography>

                                    <Typography variant="h6" sx={{ marginTop: 4 }}>
                                        Location
                                    </Typography>
                                    {!showMap ? (
                                        <Button variant="contained" color="primary" onClick={handleShowMap}>
                                            Show Location
                                        </Button>
                                    ) : (
                                        <div id="map">
                                            {/* Replace with your Google Maps embed code */}
                                            <iframe 
                                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d315118.19713273226!2d77.02014295302319!3d28.53551736063534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cda155c13bb7b%3A0x960c7dc82c87271e!2sNoida%2C%20Uttar%20Pradesh%2C%20India!5e0!3m2!1sen!2sus!4v1634543701286!5m2!1sen!2sus" 
                                                width="100%" 
                                                height="300" 
                                                style={{ border: 0 }} 
                                                allowFullScreen="" 
                                                loading="lazy"
                                            ></iframe>
                                        </div>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
            </div>
        </>
    );
};

export default ContactPage;

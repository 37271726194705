import React, { useState } from 'react';
import './ProductFilter.css'

const ProductFilter = () => {
  const [showCategory, setShowCategory] = useState(false);

  const toggleCategory = () => {
    setShowCategory(!showCategory);
  };

  return (
    <div className='ps-0  '>
      <div className="product-filter bg-white">
        {/* Filter Headline */}
        <h2 className="filter-headline">Filters</h2>

        <div className='category-filter'>
          {/* Category Section */}
          <h3>Categories</h3>
          <div class="accordion " id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button border-rounded-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  Men's Footwear
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <ul>
                    <li>Living Room</li>
                    <li>Bedroom</li>
                    <li>Kitchen</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className='price-section mb-0 border-0'>
          {/* Price Section */}
          <h3>Price</h3>
          <input type="range" min="0" max="100" />
          <select className='min-price'>
            <option value="min">Min to 2500</option>
          </select>
        </div>
      </div>


      {/*  */}
      <div className="accordion accordion-list" id="accordionExample">
        {/* BRAND */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingBrand">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseBrand"
              aria-expanded="false"
              aria-controls="collapseBrand"
            >
              BRAND
            </button>
          </h2>
          <div
            id="collapseBrand"
            className="accordion-collapse collapse"
            aria-labelledby="headingBrand"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <label>
                <input type="Checkbox" name="brand" value="Atomberg" /> Atomberg
              </label>
            </div>
          </div>
        </div>

        {/* SIZE UK/INDIA */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingSize">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSize"
              aria-expanded="false"
              aria-controls="collapseSize"
            >
              SIZE UK/INDIA
            </button>
          </h2>
          <div
            id="collapseSize"
            className="accordion-collapse collapse"
            aria-labelledby="headingSize"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
            <div>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" value="4" id="checkbox4" />
        <label className="form-check-label" htmlFor="checkbox4">
          4
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" type="checkbox" value="5" id="checkbox5" />
        <label className="form-check-label" htmlFor="checkbox5">
          5
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" type="checkbox" value="6" id="checkbox6" />
        <label className="form-check-label" htmlFor="checkbox6">
          6
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" type="checkbox" value="7" id="checkbox7" />
        <label className="form-check-label" htmlFor="checkbox7">
          7
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" type="checkbox" value="8" id="checkbox8" />
        <label className="form-check-label" htmlFor="checkbox8">
          8
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" type="checkbox" value="9" id="checkbox9" />
        <label className="form-check-label" htmlFor="checkbox9">
          9
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" type="checkbox" value="10" id="checkbox10" />
        <label className="form-check-label" htmlFor="checkbox10">
          10
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" type="checkbox" value="11" id="checkbox11" />
        <label className="form-check-label" htmlFor="checkbox11">
          11
        </label>
      </div>

      <div className="form-check">
        <input className="form-check-input" type="checkbox" value="12" id="checkbox12" />
        <label className="form-check-label" htmlFor="checkbox12">
          12
        </label>
      </div>
    </div>
            </div>
          </div>
        </div>

        {/* CUSTOMER RATINGS */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingRatings">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseRatings"
              aria-expanded="false"
              aria-controls="collapseRatings"
            >
              CUSTOMER RATINGS
            </button>
          </h2>
          <div
            id="collapseRatings"
            className="accordion-collapse collapse"
            aria-labelledby="headingRatings"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <label>
                <input type="checkbox" /> 4 Star & Above
              </label>
            </div>
          </div>
        </div>

        {/* AVAILABILITY */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingAvailability">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseAvailability"
              aria-expanded="false"
              aria-controls="collapseAvailability"
            >
              AVAILABILITY
            </button>
          </h2>
          <div
            id="collapseAvailability"
            className="accordion-collapse collapse"
            aria-labelledby="headingAvailability"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
               <div className="form-check">
        <input className="form-check-input" type="checkbox" value="12" id="checkbox12" />
        <label className="form-check-label" htmlFor="checkbox12">
          IncludeOut of Stock
        </label>
      </div>
            </div>
          </div>
        </div>

        {/* DISCOUNT */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingDiscount">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseDiscount"
              aria-expanded="false"
              aria-controls="collapseDiscount"
            >
              DISCOUNT
            </button>
          </h2>
          <div
            id="collapseDiscount"
            className="accordion-collapse collapse"
            aria-labelledby="headingDiscount"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
            <div className="form-check">
        <input className="form-check-input" type="checkbox" value="30" id="checkbox12" />
        <label className="form-check-label" htmlFor="checkbox12">
               30% or more
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" value="40" id="checkbox12" />
        <label className="form-check-label" htmlFor="checkbox12">
               40% or more
        </label>
      </div>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" value="50" id="checkbox12" />
        <label className="form-check-label" htmlFor="checkbox12">
               50% or more
        </label>
      </div>
            </div>
          </div>
        </div>

        {/* NEW ARRIVALS */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingArrivals">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseArrivals"
              aria-expanded="false"
              aria-controls="collapseArrivals"
            >
              NEW ARRIVALS
            </button>
          </h2>
          <div
            id="collapseArrivals"
            className="accordion-collapse collapse"
            aria-labelledby="headingArrivals"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
            <div className="form-check">
        <input className="form-check-input" type="checkbox" value="50" id="checkbox12" />
        <label className="form-check-label" htmlFor="checkbox12">
        NEW ARRIVALS
        </label>
      </div>
            </div>
          </div>
        </div>

        {/* UK INDIA SIZE */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingUKIndiaSize">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseUKIndiaSize"
              aria-expanded="false"
              aria-controls="collapseUKIndiaSize"
            >
              UK INDIA SIZE
            </button>
          </h2>
          <div
            id="collapseUKIndiaSize"
            className="accordion-collapse collapse"
            aria-labelledby="headingUKIndiaSize"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
            <div className="form-check">
        <input className="form-check-input" type="checkbox" value="10" id="checkbox12" />
        <label className="form-check-label" htmlFor="checkbox12">
         10
        </label>
      </div>
            <div className="form-check">
        <input className="form-check-input" type="checkbox" value="10" id="checkbox12" />
        <label className="form-check-label" htmlFor="checkbox12">
         10
        </label>
      </div>
            <div className="form-check">
        <input className="form-check-input" type="checkbox" value="10" id="checkbox12" />
        <label className="form-check-label" htmlFor="checkbox12">
         10
        </label>
      </div>
            <div className="form-check">
        <input className="form-check-input" type="checkbox" value="10" id="checkbox12" />
        <label className="form-check-label" htmlFor="checkbox12">
         10
        </label>
      </div>
            </div>
          </div>
        </div>

        {/* COLLECTIONS */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingCollections">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseCollections"
              aria-expanded="false"
              aria-controls="collapseCollections"
            >
              COLLECTIONS
            </button>
          </h2>
          <div
            id="collapseCollections"
            className="accordion-collapse collapse"
            aria-labelledby="headingCollections"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
            <div className="form-check">
        <input className="form-check-input" type="checkbox" value="StreetSport" id="checkbox12" />
        <label className="form-check-label" htmlFor="checkbox12">
         Street Sport
        </label>
      </div>
            <div className="form-check">
        <input className="form-check-input" type="checkbox" value="StreetSport" id="checkbox12" />
        <label className="form-check-label" htmlFor="checkbox12">
         Street Sport
        </label>
      </div>
            <div className="form-check">
        <input className="form-check-input" type="checkbox" value="StreetSport" id="checkbox12" />
        <label className="form-check-label" htmlFor="checkbox12">
         Street Sport
        </label>
      </div>
            </div>
          </div>
        </div>

        {/* OFFERS */}
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOffers">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOffers"
              aria-expanded="false"
              aria-controls="collapseOffers"
            >
              OFFERS
            </button>
          </h2>
          <div
            id="collapseOffers"
            className="accordion-collapse collapse"
            aria-labelledby="headingOffers"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="form-check">
        <input className="form-check-input" type="checkbox" value="special" id="checkbox12" />
        <label className="form-check-label" htmlFor="checkbox12">
         Special Price
        </label>
      </div>
              <div className="form-check disabled">
        <input className="form-check-input" type="checkbox" value="special" id="checkbox12" />
        <label className="form-check-label" htmlFor="checkbox12">
        Buy More, Save More
        </label>
      </div>
            </div>
          </div>
        </div>

          {/* Occasion */}
          <div className="accordion-item">
          <h2 className="accordion-header" id="headingOccasion">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOccasion"
              aria-expanded="false"
              aria-controls="collapseOccasion"
            >
             Occasion
            </button>
          </h2>
          <div
            id="collapseOccasion"
            className="accordion-collapse collapse"
            aria-labelledby="headingOccasion"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="form-check">
        <input className="form-check-input" type="checkbox" value="special" id="checkbox12" />
        <label className="form-check-label" htmlFor="checkbox12">
        Casual
        </label>
      </div>
              <div className="form-check disabled">
        <input className="form-check-input" type="checkbox" value="special" id="checkbox12" />
        <label className="form-check-label" htmlFor="checkbox12">
        Party
        </label>
      </div>
              <div className="form-check disabled">
        <input className="form-check-input" type="checkbox" value="special" id="checkbox12" />
        <label className="form-check-label" htmlFor="checkbox12">
        Riding
        </label>
      </div>
            </div>
          </div>
        </div>

        
          {/* Color */}
          <div className="accordion-item">
          <h2 className="accordion-header" id="headingColor">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOccasion"
              aria-expanded="false"
              aria-controls="collapseColor"
            >
             Occasion
            </button>
          </h2>
          <div
            id="collapseColor"
            className="accordion-collapse collapse"
            aria-labelledby="headingColor"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="form-check">
        <input className="form-check-input" type="checkbox" value="special" id="checkbox12" />
        <label className="form-check-label" htmlFor="checkbox12">
        Beige
        </label>
      </div>
              <div className="form-check disabled">
        <input className="form-check-input" type="checkbox" value="special" id="checkbox12" />
        <label className="form-check-label" htmlFor="checkbox12">
        Black
        </label>
      </div>
              <div className="form-check disabled">
        <input className="form-check-input" type="checkbox" value="special" id="checkbox12" />
        <label className="form-check-label" htmlFor="checkbox12">
        Blue
        </label>
      </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default ProductFilter;

import React from 'react';
import './MyOrder.css';
import { TextField, Button, Box } from '@mui/material';
import HoverDropdown from '../HoverDropdown/HoverDropdown';

function MyOrder() {
  // Sample product data array
  const orders = [
    {
      id: 1,
      name: "KINGSTON A400 240 GB SSD",
      moreInfo: "Interface: SATA III, Form Factor: 2.5 Inch",
      price: 999,
      discount: 100,
      image: 'https://rukminim2.flixcart.com/image/224/224/ksnjp8w0/headphone/t/o/k/btg-1-truke-original-imag66cyarerhhgr.jpeg?q=90',
      status: 'Delivered',
    },
    {
      id: 2,
      name: "Logitech Mouse",
      moreInfo: "Wireless, Ergonomic Design",
      price: 1499,
      discount: 200,
      image: 'https://rukminim2.flixcart.com/image/224/224/ksnjp8w0/headphone/t/o/k/btg-1-truke-original-imag66cyarerhhgr.jpeg?q=90',
      status: 'On the way',
    },
    // Add more products as needed
  ];

  return (
    <>
      <HoverDropdown />
      <div className='container-fluid section-margin-top section-margin-bottom product-list-containet'>
        <div className='row g-0 '>
          <div className='col-md-2  col-right-space d-none d-lg-block'>
            <div className='white-section  padding-20-15'>
              <div className="">
                <h5 className='headline mb-3 ps-0'>Filters</h5>
                <div className="mb-3">
                  <h6>Order Status</h6>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="onTheWay" />
                    <label className="form-check-label" htmlFor="onTheWay">On the way</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="delivered" />
                    <label className="form-check-label" htmlFor="delivered">Delivered</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="cancelled" />
                    <label className="form-check-label" htmlFor="cancelled">Cancelled</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="returned" />
                    <label className="form-check-label" htmlFor="returned">Returned</label>
                  </div>
                </div>
                <div className="mb-3">
                  <h6>Order Time</h6>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="last30Days" />
                    <label className="form-check-label" htmlFor="last30Days">Last 30 days</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="year2023" />
                    <label className="form-check-label" htmlFor="year2023">2023</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="year2022" />
                    <label className="form-check-label" htmlFor="year2022">2022</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="year2021" />
                    <label className="form-check-label" htmlFor="year2021">2021</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="year2020" />
                    <label className="form-check-label" htmlFor="year2020">2020</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="older" />
                    <label className="form-check-label" htmlFor="older">Older</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-10'>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                label="Search your orders here"
                variant="outlined"
                size="small"
                sx={{
                  backgroundColor: 'white',
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0
                }}
                InputProps={{
                  style: {
                    backgroundColor: 'white'
                  }
                }}
                className='searchInput'
              />
              <Button
                variant="contained"
                color="primary"
                sx={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0
                }}
                className='searchBtn'
              >
                Search
              </Button>
            </Box>
            <div className='white-section h-100 mt-0 padding-20-5 mt-3'>
              <div className='container-padding '>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item"><a href="#">My Order</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Order</li>
                  </ol>
                </nav>
                <div className='order-content'>
  {/* Empty container for no orders */}
  {orders.length === 0 && (
    <div className='empty-container'>
      <div>
        <div className='text-center d-flex justify-content-center'>
          <img className='empty-img' src='https://cdn-icons-png.freepik.com/512/4984/4984782.png' alt="No orders" />
        </div>
        <p className='h5 py-3'>You have no orders</p>
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ ml: 1 }}
        >
          Start Shopping
        </Button>
      </div>
    </div>
  )}

  {/* Order list for displaying orders */}
  {orders.length > 0 && (
    <div className="row">
      {orders.map((order) => (
        <div key={order.id} className="col-md-4 mb-4"> {/* 3 cards in a row */}
          <div className="order-item">
            <img src={order.image} alt={order.name} className="order-image" />
            <div className="order-details">
              <h5>{order.name}</h5>
              <p>{order.moreInfo}</p>
              <div className="order-price">
                <span>Price: ₹{order.price}</span>
                <span>Discount: -₹{order.discount}</span>
              </div>
              <span>Status: {order.status}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  )}
</div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyOrder;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import '../MobileNavbar.css'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './Component/Navbar/Navbar'
import Footer from './Component/Footer/Footer'
import Home from './Component/Home/Home';
import Register from './Component/Register/Register';
import ProductList from './Component/ProductList/ProductList';
import ProductBuyPage from './Component/ProductBuyPage/ProductBuyPage';
import Login from './Component/Login/Login';
import Cart from './Component/Cart/Cart'
import Account from './Component/Account/Account';
import MyOrder from './Component/MyOrder/MyOrder';
// import { Payment } from '@mui/icons-material';
import Payment from './Component/pages/Payment';
import Shipping from './Component/pages/Shipping';
import CancellationRefund from './Component/pages/CancellationRefund';
import Faq from './Component/pages/Faq';
import ScrollToTop from './Component/PageScroll/ScrollToTop';
import PrivacyPolicy from './Component/pages/PrivacyPolicy';
import TermsAndConditions from './Component/pages/TermsAndConditions';
import Security from './Component/pages/Security';
import EPRCompliance from './Component/pages/EPRCompliance';
import ContactPage from './Component/Contact/ContactPage';
import About from './Component/About/About';
import NotificationPage from './Component/UserSupport/NotificationPage';
import CustomerSupportPage from './Component/UserSupport/CustomerSupportPage';
import WishlistPage from './Component/WishlistPage.js/WhishlistPage';
import GiftCardPage from './Component/GiftCard/GiftCardPage';
import BecomeSeller from './Component/BecomeSeller/BecomeSeller';
import MobileNavbar from './Component/Navbar/MobileNavbar';
import AllCategories from './Component/Categories/AllCategories';
import BecomeNavbar from './Component/BecomeSeller/BecomeNavbar';
// import BecomeSeller from './Component/BecomeSeller/BecomeSeller';





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/productlist" element={<ProductList />} />
        <Route path="/productbuy" element={<ProductBuyPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="account" element={<Account />} />
        <Route path="orderPage" element={<MyOrder />} />
        <Route path="payment" element={<Payment />} />
        <Route path="shipping" element={<Shipping />} />
        <Route path="cancellation" element={<CancellationRefund />} />
        <Route path="faq" element={<Faq />} />
        <Route path="privacy" element={<PrivacyPolicy />} />
        <Route path="terms" element={<TermsAndConditions />} />
        <Route path="security" element={<Security />} />
        <Route path="epr-compliance" element={<EPRCompliance />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="about" element={<About />} />
        <Route path="notification" element={<NotificationPage />} />
        <Route path="cusomer_support" element={<CustomerSupportPage />} />
        <Route path="whishlist" element={<WishlistPage />} />
        <Route path="gift_card" element={<GiftCardPage/>}/>
        <Route path="becomeseller" element={<BecomeSeller/>}/>
        <Route path="mobileNavbar" element={<MobileNavbar/>}/>
        <Route path="allcategories" element={<AllCategories/>}/>
        {/* <Route path="becomeseller" element={<BecomeSeller/>}/> */}
        <Route path="becomenavbar" element={<BecomeNavbar/>}/>
      </Routes>
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();

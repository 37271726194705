import React from 'react'
import './Best_deal.css'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


const options = {
    responsive: {
      0: {
        items: 2, // Small screen mein 2 cards
      },
      768: {
        items: 6, // Larger screen mein 6 cards
      },
    },
    nav: true, // Show next and prev buttons
    dots: false, // Show dots navigation
  };


  const cardData = [
    {
      img: "https://rukminim2.flixcart.com/image/312/312/xif0q/monitor/d/s/x/zeb-ac32fhd-led-full-hd-32-2022-zeb-ac32fhd-zebronics-original-imagydzpqfy3tvaq.jpeg?q=70",
      title: "Women's Flats",
      description: " 300",
    },
    {
      img: "https://rukminim2.flixcart.com/image/612/612/xif0q/mouse/a/t/d/-original-imagwde4z4v2cy6g.jpeg?q=70",
      title: "Women's Flats",
      description: " 300",
    },
    {
      img: "https://rukminim2.flixcart.com/image/612/612/xif0q/printer/r/7/3/dcp-l2520d-ind-brother-original-imah3fzquk9xegpr.jpeg?q=70",
      title: "Home Product",
      description: " 300",
    },
    {
      img: "https://rukminim2.flixcart.com/image/312/312/xif0q/tablet/d/w/m/-original-imah2sp24tznr5sh.jpeg?q=70",
      title: "Perfume",
      description: " 300",
    },
    {
      img: "https://rukminim2.flixcart.com/image/612/612/xif0q/printer/s/8/d/-original-imafkykednshkhx5.jpeg?q=70",
      title: "Perfume",
      description: " 300",
    },
    {
     img: "https://rukminim2.flixcart.com/image/312/312/xif0q/tablet/d/w/m/-original-imah2sp24tznr5sh.jpeg?q=70",
      title: "Perfume",
      description: " 300",
    },
    {
        img: "https://rukminim2.flixcart.com/image/312/312/xif0q/tablet/d/w/m/-original-imah2sp24tznr5sh.jpeg?q=70",
        title: "Perfume",
        description: " 300",
      },
      {
        img: "https://rukminim2.flixcart.com/image/612/612/ksgehzk0/mouse/x/u/y/zeb-jaguar-zebronics-original-imag6ym5vfjpg5eg.jpeg?q=70",
        title: "Perfume",
        description: " 300",
      },
  ];



function Best_Deal() {
  return (
    <>
    
    <div className='product_300'>
<div className='container-fluid'>
<div className='white-section'>
<div className='d-flex justify-content-between align-items-center'>
<h4 className='headline'>Best Deals on Electronics</h4>
<i class="fas fa-chevron-right right-chev"></i>
</div>
<OwlCarousel className="owl-theme" {...options}>
  {cardData.map((card, index) => (
    <div className="item" key={index}>
      <div className="card text-start">
        <div className='img-box'>
          <img className="card-img-top" src={card.img} alt={card.title} />
        </div>
        <div className="card-body">
          <h4 className="card-title">{card.title}</h4>
          <p className="card-text">Under &#8377;{card.description}</p>
        </div>
      </div>
    </div>
  ))}
</OwlCarousel>
</div>

</div>
</div>
    <div className='product_300 d-none'>
        <div className='container-fluid'>
            <h4 className='headline'>Under RS 300</h4>
   
            <div className="cusmtom-row">
                <div className=''>
                    <div className="card text-start">
                        {/* <img className="card-img-top" src="https://rukminim2.flixcart.com/image/612/612/xif0q/pot-pan/k/p/e/iron-tadka-kadai-kadhai-for-cooking-deep-frying-with-induction-original-imagwnzhwst8nnnd.jpeg?q=70" alt="Title" /> */}
                        <div className='img-box'>
                            <img className="card-img-top" src="https://rukminim2.flixcart.com/image/612/612/xif0q/sandal/o/i/e/8-411-heighten-white-original-imagquk58ya3vvqm.jpeg?q=70" alt="Title" />
                        </div>
                        <div className="card-body">
                            <h4 className="card-title">Women's Flats</h4>
                            <p className="card-text">Under 300</p>
                        </div>
                    </div>

                </div>
                <div className=''>
                    <div className="card text-start">
                        <div className='img-box'>
                            <img className="card-img-top" src="https://rukminim2.flixcart.com/image/612/612/xif0q/sandal/f/9/m/8-10613510686-8-khadim-s-beige-original-imah4ebgrda5bt4d.jpeg?q=70" alt="Title" />
                        </div>

                        <div className="card-body">
                        <h4 className="card-title">Women's Flats</h4>
                            <p className="card-text">Under 300</p>
                        </div>
                    </div>

                </div>
                <div className=''>
                    <div className="card text-start">
                        <div className='img-box'>
                            <img className="card-img-top" src="https://rukminim2.flixcart.com/image/612/612/xif0q/vehicle-lubricant/6/s/z/1000-coolant-pink-1-ltr-p1-esson-original-imagyeyd9cptrvae.jpeg?q=70" alt="Title" />
                        </div>

                        <div className="card-body">
                            <h4 className='card-title'>Home Product</h4>
                            <p className="card-text">Under 300</p>
                        </div>
                    </div>

                </div>
                <div className=''>
                    <div className="card text-start">
                        <div className='img-box'>
                            <img className="card-img-top" src="https://rukminim2.flixcart.com/image/612/612/xif0q/perfume/g/a/z/40-date-woman-glam-woman-perfume-combo-2x20ml-with-floral-woody-original-imahyrrjgmjkfrsg.jpeg?q=70" alt="Title" />
                        </div>
                        <div className="card-body">
                            <h4 className='card-title'>Prefume</h4>
                            <p className="card-text">Under 300</p>
                        </div>
                    </div>

                </div>
                <div className=''>
                    <div className="card text-start">
                        <div className='img-box'>
                            <img className="card-img-top" src="https://rukminim2.flixcart.com/image/612/612/xif0q/perfume/g/a/z/40-date-woman-glam-woman-perfume-combo-2x20ml-with-floral-woody-original-imahyrrjgmjkfrsg.jpeg?q=70" alt="Title" />
                        </div>

                        <div className="card-body">
                            <h4 className='card-title'>Prefume</h4>
                            <p className="card-text">Under 300</p>
                        </div>
                    </div>

                </div>
                <div className=''>
                    <div className="card text-start">
                        <div className='img-box'>
                            <img className="card-img-top" src="https://rukminim2.flixcart.com/image/612/612/xif0q/perfume/g/a/z/40-date-woman-glam-woman-perfume-combo-2x20ml-with-floral-woody-original-imahyrrjgmjkfrsg.jpeg?q=70" alt="Title" />
                        </div>

                        <div className="card-body">
                            <h4 className='card-title'>Prefume</h4>
                            <p className="card-text">Under 300</p>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</>
  )
}

export default Best_Deal
import React, { useState } from "react";
import "./Register.css"; // Custom CSS for styling

const Register = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    mobile: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
    console.log("User registered:", user);
  };

  return (
    <div className="register-container">
      <div className="register-box">
        <h2>Ebazaar Registration</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-field">
            <input
              type="text"
              name="name"
              value={user.name}
              onChange={handleInputChange}
              placeholder="Enter your Name"
              required
            />
          </div>
          <div className="input-field">
            <input
              type="email"
              name="email"
              value={user.email}
              onChange={handleInputChange}
              placeholder="Enter your Email"
              required
            />
          </div>
          <div className="input-field">
            <input
              type="password"
              name="password"
              value={user.password}
              onChange={handleInputChange}
              placeholder="Create a Password"
              required
            />
          </div>
          <div className="input-field">
            <input
              type="tel"
              name="mobile"
              value={user.mobile}
              onChange={handleInputChange}
              placeholder="Enter your Mobile Number"
              required
            />
          </div>
          <button type="submit" className="register-btn">
            Register
          </button>
        </form>
        {/* <div className="alternative-login">
          <p>Or register with</p>
          <div className="social-login">
            <button className="google-btn">Google</button>
            <button className="facebook-btn">Facebook</button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Register;

import React, { useState } from 'react';
import CartImg from './cart.webp';
import './Cart.css';
import HoverDropdown from '../HoverDropdown/HoverDropdown';

function Cart() {
  const [quantity, setQuantity] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [productIdToRemove, setProductIdToRemove] = useState(null);

  // Function to handle product removal
  const handleRemove = (productId) => {
    setProductIdToRemove(productId);
    setShowModal(true); // Show modal when Remove button is clicked
  };

  // Function to confirm removal
  const confirmRemove = () => {
    console.log(`Product ${productIdToRemove} removed from cart`);
    setShowModal(false); // Close the modal after confirmation
    // Implement actual remove functionality here
  };

  // Function to change quantity
  const handleQuantityChange = (type) => {
    if (type === 'increment') {
      setQuantity((prev) => prev + 1);
    } else if (type === 'decrement' && quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  };

  // Sample product data
  const product = {
    id: 1,
    name: "KINGSTON A400 240 GB Laptop, Desktop Internal Solid S",
    moreInfo: "Interface: SATA III, Form Factor: 2.5 Inch",
    stock: "In Stock",
    price: 999,
    discount: 100,
    image: 'https://rukminim2.flixcart.com/image/224/224/ksnjp8w0/headphone/t/o/k/btg-1-truke-original-imag66cyarerhhgr.jpeg?q=90',
  };

  return (
    <>
      <HoverDropdown />
      <div className='d-none'>
        <div className='container-fluid section-margin-top-bottom'>
          <div className='cart-body text-center bg-white'>
            <img className='cart-img' width={300} src={CartImg} alt="cart" />
            <div className='cart-content'>
              <div className='empty-container'>
                <div>
                  <p className='h4'>Missing Cart items?</p>
                  <p><small>Login to see the items you added previously</small></p>
                  <button className='btn cart-login-btn'>Login</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container  section-margin-top-bottom'>
        <div className='row'>
          {/* Left Column: Product Details */}
          <div className='col-md-8 '>
             <div className='product-cart-col white-section'>
             <div className='card product-details'>
              <div className='card-body'>
                <div>
                  <img src={product.image} alt={product.name} />
                </div>
                <div>
                  <h5>{product.name}</h5>
                  <small>{product.moreInfo}</small>
                  <p className='green'>{product.stock}</p>
                </div>
              </div>
              <div className='card-footer'>
                <h5>Extended Warranty Plan by OneAssist</h5>
                <p className='text-muted'>Extended Warranty for Headphones (1 Year)</p>
                <div className='price-cart'>
                  <span className='current-price'>&#8377; 49</span> <s className='real-price'> &#8377;99</s>
                  <span className='discount'>50% off</span>
                </div>
                <p>Get Full Warranty Coverage and protection against damages. Add Extended Warranty to make your headphone work longer</p>
                
                  <div className='d-flex gap-3'>
                    {/* Quantity Selector */}
                <div className='quantity-selector'>
                  <button className='btn btn-outline-secondary' onClick={() => handleQuantityChange('decrement')}>-</button>
                  <span className='quantity'>{quantity}</span>
                  <button className='btn btn-outline-secondary' onClick={() => handleQuantityChange('increment')}>+</button>
                </div>

                <button 
                  className='btn btn-danger' 
                  onClick={() => handleRemove(product.id)}
                >
                  Remove
                </button>
                  </div>
              </div>
            </div>
             <div className='card product-details'>
              <div className='card-body'>
                <div>
                  <img src={product.image} alt={product.name} />
                </div>
                <div>
                  <h5>{product.name}</h5>
                  <small>{product.moreInfo}</small>
                  <p className='green'>{product.stock}</p>
                </div>
              </div>
              <div className='card-footer'>
                <h5>Extended Warranty Plan by OneAssist</h5>
                <p className='text-muted'>Extended Warranty for Headphones (1 Year)</p>
                <div className='price-cart'>
                  <span className='current-price'>&#8377; 49</span> <s className='real-price'> &#8377;99</s>
                  <span className='discount'>50% off</span>
                </div>
                <p>Get Full Warranty Coverage and protection against damages. Add Extended Warranty to make your headphone work longer</p>
                
                  <div className='d-flex gap-3'>
                    {/* Quantity Selector */}
                <div className='quantity-selector'>
                  <button className='btn btn-outline-secondary' onClick={() => handleQuantityChange('decrement')}>-</button>
                  <span className='quantity'>{quantity}</span>
                  <button className='btn btn-outline-secondary' onClick={() => handleQuantityChange('increment')}>+</button>
                </div>

                <button 
                  className='btn btn-danger' 
                  onClick={() => handleRemove(product.id)}
                >
                  Remove
                </button>
                  </div>
              </div>
            </div>
             <div className='card product-details'>
              <div className='card-body'>
                <div>
                  <img src={product.image} alt={product.name} />
                </div>
                <div>
                  <h5>{product.name}</h5>
                  <small>{product.moreInfo}</small>
                  <p className='green'>{product.stock}</p>
                </div>
              </div>
              <div className='card-footer'>
                <h5>Extended Warranty Plan by OneAssist</h5>
                <p className='text-muted'>Extended Warranty for Headphones (1 Year)</p>
                <div className='price-cart'>
                  <span className='current-price'>&#8377; 49</span> <s className='real-price'> &#8377;99</s>
                  <span className='discount'>50% off</span>
                </div>
                <p>Get Full Warranty Coverage and protection against damages. Add Extended Warranty to make your headphone work longer</p>
                
                  <div className='d-flex gap-3'>
                    {/* Quantity Selector */}
                <div className='quantity-selector'>
                  <button className='btn btn-outline-secondary' onClick={() => handleQuantityChange('decrement')}>-</button>
                  <span className='quantity'>{quantity}</span>
                  <button className='btn btn-outline-secondary' onClick={() => handleQuantityChange('increment')}>+</button>
                </div>

                <button 
                  className='btn btn-danger' 
                  onClick={() => handleRemove(product.id)}
                >
                  Remove
                </button>
                  </div>
              </div>
            </div>
             </div>
          </div>

          {/* Right Column: Price, Discount, Platform, Delivery, Total */}
          <div className='col-md-4 white-section price-col'>
            <div className='price-details'>
              <h5 className='ps-0 headline bottom-border'>Price Details</h5>
              <div className='price-item'>
                <span>Product Price:</span>
                <span>₹{product.price}</span>
              </div>
              <div className='price-item'>
                <span>Discount:</span>
                <span>-₹{product.discount}</span>
              </div>
              <div className='price-item'>
                <span>Platform:</span>
                <span>EBAZAAR</span>
              </div>
              <div className='price-item'>
                <span>Delivery:</span>
                <span>Free</span>
              </div>
              <div className='total-price'>
                <h6>Total:</h6>
                <h4>₹{product.price - product.discount}</h4>
              </div>
              <p className='save-offfer text-center mt-3'>You will save &#8377;13,48 on this order</p>
               <p className='mt-5 text-muted'>Safe and Secure Payments.Easy returns.100% Authentic products.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Bootstrap Modal */}
      <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1" aria-labelledby="removeModalLabel" aria-hidden={!showModal}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="removeModalLabel">Confirm Removal</h5>
              <button type="button" className="btn-close" onClick={() => setShowModal(false)} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Are you sure you want to remove this item from your cart?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Cancel</button>
              <button type="button" className="btn btn-danger" onClick={confirmRemove}>Remove</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cart;

import React from 'react'
import './Categories.css'
function Categories() {
  return (
   <>
    <div className='tee container-fluid mt-4'>
                        <div className="container-fluid   category-nav">
                              <div className="level-same">
                                    <div className='category-img'>
                                          <img src="https://rukminim2.flixcart.com/flap/80/80/image/29327f40e9c4d26b.png?q=100" alt="" />
                                    </div>
                                    <a className='nav-link' href="">Grocery</a>
                              </div>
                              <div className="level-same">
                                    <div className='category-img'>
                                          <img src="https://rukminim2.flixcart.com/flap/80/80/image/22fddf3c7da4c4f4.png?q=100" alt="" />
                                    </div>
                                    <a href="" className='nav-link'>Mobile</a>
                              </div>
                              <div className="dropdown level-same">
                                    <div className='category-img'>
                                          <img src="https://rukminim2.flixcart.com/fk-p-flap/80/80/image/0d75b34f7d8fbcb3.png?q=100" alt="" />
                                    </div>
                                    <a className="dropdown-toggle nav-link" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          Fashion
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                          {/* <!-- Pent submenu --> */}
                                          <div className="dropdown-submenu">
                                                <a className="dropdown-item" href="#">Pent</a>
                                                <div className="dropdown-menu">
                                                      <a className="dropdown-item" href="#">Red Pent</a>
                                                      <a className="dropdown-item" href="#">Blue Pent</a>
                                                      <a className="dropdown-item" href="#">Green Pent</a>
                                                </div>
                                          </div>
                                          {/* <!-- Shirt submenu --> */}
                                          <div className="dropdown-submenu">
                                                <a className="dropdown-item" href="#">Shirt</a>
                                                <div className="dropdown-menu">
                                                      <a className="dropdown-item" href="#">Red Shirt</a>
                                                      <a className="dropdown-item" href="#">Pink Shirt</a>
                                                      <a className="dropdown-item" href="#">Blue Shirt</a>
                                                </div>
                                          </div>
                                          {/* <!-- TShirt submenu --> */}
                                          <div className="dropdown-submenu">
                                                <a className="dropdown-item nav-link" href="#">TShirt</a>
                                                <div className="dropdown-menu">
                                                      <a className="dropdown-item" href="#">Black TShirt</a>
                                                      <a className="dropdown-item" href="#">White TShirt</a>
                                                      <a className="dropdown-item" href="#">Yellow TShirt</a>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                              {/*  */}
                              <div className="dropdown level-same">
                                    <div className='category-img'>
                                          <img src="https://rukminim2.flixcart.com/flap/80/80/image/69c6589653afdb9a.png?q=100" alt="" />
                                    </div>
                                    <a className="dropdown-toggle nav-link" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          Electronics
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                          {/* <!-- Pent submenu --> */}
                                          <div className="dropdown-submenu">
                                                <a className="dropdown-item" href="#">Pent</a>
                                                <div className="dropdown-menu">
                                                      <a className="dropdown-item" href="#">Red Pent</a>
                                                      <a className="dropdown-item" href="#">Blue Pent</a>
                                                      <a className="dropdown-item" href="#">Green Pent</a>
                                                </div>
                                          </div>
                                          {/* <!-- Shirt submenu --> */}
                                          <div className="dropdown-submenu">
                                                <a className="dropdown-item" href="#">Shirt</a>
                                                <div className="dropdown-menu">
                                                      <a className="dropdown-item" href="#">Red Shirt</a>
                                                      <a className="dropdown-item" href="#">Pink Shirt</a>
                                                      <a className="dropdown-item" href="#">Blue Shirt</a>
                                                </div>
                                          </div>
                                          {/* <!-- TShirt submenu --> */}
                                          <div className="dropdown-submenu">
                                                <a className="dropdown-item" href="#">TShirt</a>
                                                <div className="dropdown-menu">
                                                      <a className="dropdown-item" href="#">Black TShirt</a>
                                                      <a className="dropdown-item" href="#">White TShirt</a>
                                                      <a className="dropdown-item" href="#">Yellow TShirt</a>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                              {/*  */}
                              <div className="dropdown level-same">
                                    <div className='category-img' >
                                          <img src="https://rukminim2.flixcart.com/flap/80/80/image/ab7e2b022a4587dd.jpg?q=100" alt="" />
                                    </div>
                                    <a className="dropdown-toggle nav-link" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          Home & Furniture
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                          {/* <!-- Pent submenu --> */}
                                          <div className="dropdown-submenu">
                                                <a className="dropdown-item" href="#">Pent</a>
                                                <div className="dropdown-menu">
                                                      <a className="dropdown-item" href="#">Red Pent</a>
                                                      <a className="dropdown-item" href="#">Blue Pent</a>
                                                      <a className="dropdown-item" href="#">Green Pent</a>
                                                </div>
                                          </div>
                                          {/* <!-- Shirt submenu --> */}
                                          <div className="dropdown-submenu">
                                                <a className="dropdown-item" href="#">Shirt</a>
                                                <div className="dropdown-menu">
                                                      <a className="dropdown-item" href="#">Red Shirt</a>
                                                      <a className="dropdown-item" href="#">Pink Shirt</a>
                                                      <a className="dropdown-item" href="#">Blue Shirt</a>
                                                </div>
                                          </div>
                                          {/* <!-- TShirt submenu --> */}
                                          <div className="dropdown-submenu">
                                                <a className="dropdown-item" href="#">TShirt</a>
                                                <div className="dropdown-menu">
                                                      <a className="dropdown-item" href="#">Black TShirt</a>
                                                      <a className="dropdown-item" href="#">White TShirt</a>
                                                      <a className="dropdown-item" href="#">Yellow TShirt</a>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                              <div className="level-same">
                                    <div className='category-img' >
                                          <img src="https://rukminim2.flixcart.com/fk-p-flap/80/80/image/0139228b2f7eb413.jpg?q=100" alt="" />
                                    </div>
                                    <a href="" className='nav-link'>Appliances</a>
                              </div>
                              <div className="level-same">
                                    <div className='category-img' >
                                          <img src="https://rukminim2.flixcart.com/flap/80/80/image/71050627a56b4693.png?q=100" alt="" />
                                    </div>
                                    <a href="" className='nav-link'>Flight Bookings</a>
                              </div>
                              <div className="level-same">
                                    <div className='category-img' >
                                          <img src="https://rukminim2.flixcart.com/flap/80/80/image/dff3f7adcf3a90c6.png?q=100" alt="" />
                                    </div>
                                    <a href="" className='nav-link'>Beauty, Toys & More</a>
                              </div>
                              <div className="level-same">
                                    <div className='category-img'>
                                          <img src="https://rukminim2.flixcart.com/fk-p-flap/80/80/image/05d708653beff580.png?q=100" alt="" />
                                    </div>
                                    <a href="" className='nav-link'>Two Wheelers</a>
                              </div>
                        </div>
              



                  </div>
   </>
  )
}

export default Categories
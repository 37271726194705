import React from 'react';
import './Categories.css'


const categories = [
  { id: 1, name: 'Smartphones', img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSI2FiOAgw_LycADPAh6qz-O9_yl4VOALeRhA&s' },
  { id: 2, name: 'Laptops', img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSI2FiOAgw_LycADPAh6qz-O9_yl4VOALeRhA&s' },
  { id: 3, name: 'Headphones', img: 'https://static.vecteezy.com/system/resources/thumbnails/024/558/883/small/black-wireless-headphones-isolated-on-transparent-background-ai-generated-png.png' },
  { id: 4, name: 'Smartwatches', img: 'https://png.pngtree.com/png-vector/20240309/ourmid/pngtree-the-smartwatch-banner-png-image_11919210.png' },
  { id: 5, name: 'Cameras', img: 'https://pngimg.com/uploads/photo_camera/photo_camera_PNG101601.png' },
  { id: 6, name: 'Tablets', img: 'https://www.freeiconspng.com/thumbs/tablet-png/tablets-and-smart-phones-png-28.png' },
];

const AllCategories = () => {
  return (
    <div className="container allCategories  white-section section-padding">
      <h1 className="text-center mb-4">All Categories</h1>
      <div className="row">
        {categories.map((category) => (
          <div className="col-4 col-sm-6 col-md-4 mb-4" key={category.id}>
            <div className="card">
              <img src={category.img} className="card-img-top" alt={category.name} />
              <div className="card-body">
                <h5 className="card-title text-center">{category.name}</h5>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllCategories;

import React from 'react'
import { Grid, Paper, Typography, TextField, Button } from '@mui/material';
import './Login.css'
import HoverDropdown from '../HoverDropdown/HoverDropdown';
import { Navigate, useNavigate } from 'react-router-dom';
import DropdownWithHover from '../DropdownWithHover/DropdownWithHover';

function Login() {
  const navigate = useNavigate(); // Get the navigate function
  const PrivacyClick = () =>{
    navigate('/privacy')
  }
  return (
       
      <>
    
      <HoverDropdown/>
      <DropdownWithHover/>
           <div className=' section-margin-top-bottom'>
        <div className='login-Container'>
            <div className='login-form'>
            <div className='login-left-content'>
        <Typography variant="h5" color="white " className='mb-3'>Login</Typography>
          <p> Get access to your Orders,</p>
          <p>Wishlist and Recommendations</p>
        <img  src="https://cdni.iconscout.com/illustration/premium/thumb/login-page-illustration-download-in-svg-png-gif-file-formats--app-developing-development-secure-mobile-webapp-and-pack-design-illustrations-3783954.png" alt="Login" className="bottom-image" />
        </div>
        {/*  */}
        <div className='login-right-content'>
      
        <TextField
          label="Email"
          variant="standard"
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          type="password"
          variant="standard"
          fullWidth
          margin="normal"
        />
          <Typography className='small' variant="body1" align="center">By Continuing, you agree to Ebazaar's <a >Terms of Use</a> and <a href='#' onClick={PrivacyClick}>Privacy Plolicy.</a></Typography>
          {/* <small className='text-center w-100 border'>By Continuing, you agree to Ebazaar's <a href='#'>Terms of Use</a> and <a href='#'>Privacy Plolicy.</a></small> */}
        <Button variant="contained" className='btn-login' fullWidth>
          Login
        </Button>
        <div className='register-info'>
          <Typography variant="body2">
            You don't have an account? <a href="#">Sign Up</a>
          </Typography>
        </div>
        </div>
            </div>
        </div>
      </div>
      </>
  )
}

export default Login
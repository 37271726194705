import React from 'react'
import './Banner.css'
function Banner() {
  return (
      <>
         <div className='container-fluid'>
         <img className='img-fluid d-block d-lg-none' src='https://rukminim2.flixcart.com/fk-p-flap/480/210/image/a1f460c8af3d90d9.jpg?q=20' />
                        <div className="">
                              <div id="flipkartBanner" className="carousel slide banner d-none d-lg-block" data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                          {/*       <!-- Slide 1 --> */}
                                          <div className="carousel-item active">
                                                <img src="https://rukminim2.flixcart.com/fk-p-flap/1600/270/image/9088baf34bef453a.jpg?q=20" alt="Banner 1" />
                                          </div>
                                          {/*    <!-- Slide 2 --> */}

                                          <div className="carousel-item">
                                                <img src="https://rukminim2.flixcart.com/fk-p-flap/1600/270/image/60fc6fc5275ddced.jpeg?q=20" alt="Banner 3" />
                                          </div>
                                          <div className="carousel-item">
                                                <img src="https://rukminim2.flixcart.com/fk-p-flap/1600/270/image/0e03659c089a3055.jpg?q=20" alt="Banner 2" />
                                          </div>
                                          {/*   <!-- Slide 3 --> */}

                                    </div>
                                    {/*  <!-- Carousel Controls --> */}
                                    <button className="carousel-control-prev" type="button" data-bs-target="#flipkartBanner" data-bs-slide="prev">
                                          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                          <span className="visually-hidden">Previous</span>
                                    </button>
                                    <button className="carousel-control-next" type="button" data-bs-target="#flipkartBanner" data-bs-slide="next">
                                          <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                          <span className="visually-hidden">Next</span>
                                    </button>
                              </div>
                        </div>
         </div>
      </>
  )
}

export default Banner
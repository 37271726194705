import React from "react";
import './Review.css'

const Review = () => {
  return (
    <div className="mt-4">
      <h2>Ratings & Reviews</h2>

      <div className="row">
        {/* Left Column: What our customers felt */}
        <div className="col-md-6">
          <h5 className="customer-feedback">What our customers felt:</h5>
          <div className="review-btn-container">
            <button className="feedback-item  mb-2">Look</button>
            <button className="feedback-item  mb-2">Colour</button>
            <button className="feedback-item  mb-2">Comfort</button>
            <button className="feedback-item  mb-2">Material Quality</button>
            <button className="feedback-item  mb-2">Light Weight</button>
            <button className="feedback-item  mb-2">True to Specs</button>
          </div>
        </div>

        {/* Right Column: Images uploaded by customers */}
        <div className="col-md-6">
          <h5>Images uploaded by customers:</h5>
          <div className="customer-images d-flex">
            <img
              src="https://rukminim2.flixcart.com/image/612/612/xif0q/shoe/6/o/g/7-29825830-41-hrx-by-hrithik-roshan-beige-original-imah5fdryqagqhyc.jpeg?q=70"
              alt="ADIDAS CushUp Walking Shoes"
              className="img-fluid"
            />
            <img
              src="https://rukminim2.flixcart.com/image/612/612/xif0q/shoe/6/o/g/7-29825830-41-hrx-by-hrithik-roshan-beige-original-imah5fdryqagqhyc.jpeg?q=70"
              alt="ADIDAS CushUp Walking Shoes"
              className="img-fluid"
            />
            <img
              src="https://rukminim2.flixcart.com/image/612/612/xif0q/shoe/6/o/g/7-29825830-41-hrx-by-hrithik-roshan-beige-original-imah5fdryqagqhyc.jpeg?q=70"
              alt="ADIDAS CushUp Walking Shoes"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
   <hr></hr>
      {/* Reaction Row: Awesome with thumbs icons */}
      <div className="reaction-row ">
        <div>
          <h2 className="me-2 headline">Awesome</h2>
            <div className="qustions">
            <div className="faq-qustion">Q: Weight of the shoes?</div>
            <div className="faq-qustion">A: Approx 600 gm of pairs...</div>
            </div>
            <div className="buyer-container">
                <div className="buyer-name" >Anonymous</div>
                <div className="buyer-certied"><i class="fa-solid fa-check"></i> Certified Buyer</div>
            </div>
        </div>

        <div className="review-like-dislike border">
          <div className="like">
            <i class="fa-solid fa-thumbs-up"></i>
            <span>0</span>
          </div>
          <div className="dislicke">
            <i class="fa-solid fa-thumbs-down"></i>
            <span>0</span>
          </div>
        </div>
      
      </div>
      <div className="p-5"></div>
    </div>
  );
};

export default Review;

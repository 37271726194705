// ScrollToTop.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation(); // Yeh current route ka path deti hai

  useEffect(() => {
    window.scrollTo(0, 0); // Page ko top par le jata hai
  }, [pathname]); // Jab bhi route change hota hai, effect trigger hota hai

  return null;
};

export default ScrollToTop;

import React from 'react';
// import './BecomeNavbar.css';

const BecomeNavbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">Become a Seller</a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link" href="#list-products">List Products</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#storage-shipping">Storage & Shipping</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#receive-payments">Receive Payments</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#grow-faster">Grow Faster</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#seller-app">Seller App</a>
            </li>
          </ul>
          <div className="d-flex">
            <a href="/login" className="btn btn-outline-primary">Login</a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default BecomeNavbar;

import React from 'react';
import './Pages.css';

const Faq = () => {
    return (
        <>
            <div className='container'>
                <div className="faq-container bg-white pages-conatiner">
                    <h2>Frequently Asked Questions (FAQs)</h2>
                    <p>
                        Below are some of the most frequently asked questions. If you have any other queries, feel free to contact our customer support.
                    </p>

                    <div className='content'>
                        <h3 className='page-headline'>Account & Orders</h3>
                        <p><strong>How do I create an account?</strong></p>
                        <p>
                            Creating an account is simple. Click on the "Sign Up" button on the homepage and fill in your details. Once registered, you can start shopping.
                        </p>

                        <p><strong>How do I track my order?</strong></p>
                        <p>
                            You can track your order by logging into your account, navigating to "My Orders," and selecting the order you wish to track. A tracking link will be provided.
                        </p>
                        
                        <p><strong>Can I cancel or modify my order?</strong></p>
                        <p>
                            Yes, you can cancel or modify your order before it is shipped. Once shipped, cancellation is not possible, but you may return the product after delivery.
                        </p>
                    </div>

                    <div className='content'>
                        <h3>Shipping & Delivery</h3>
                        <p><strong>What are the shipping charges?</strong></p>
                        <p>
                            Shipping charges depend on your location and the delivery method chosen. Standard delivery is free for most orders, while express delivery might incur additional charges.
                        </p>

                        <p><strong>How long will it take for my order to arrive?</strong></p>
                        <p>
                            Delivery times depend on your location and the shipping method. Standard delivery usually takes 3-7 business days, while express delivery arrives in 1-3 business days.
                        </p>
                    </div>

                    <div className='content'>
                        <h3>Payments</h3>
                        <p><strong>What payment methods are available?</strong></p>
                        <p>
                            We offer multiple payment methods, including Credit/Debit cards, Net Banking, UPI, Digital Wallets, and Cash on Delivery (C-o-D).
                        </p>

                        <p><strong>Is my payment information secure?</strong></p>
                        <p>
                            Yes, we use industry-standard encryption technology to ensure your payment details are secure. All transactions are processed through secure payment gateways.
                        </p>
                    </div>

                    <div className='content'>
                        <h3>Returns & Refunds</h3>
                        <p><strong>How do I return a product?</strong></p>
                        <p>
                            You can return a product within 7 days of receiving it. Log into your account, go to "My Orders," and click on "Return" next to the item you wish to return. Follow the steps provided.
                        </p>

                        <p><strong>How long does it take to process a refund?</strong></p>
                        <p>
                            Refunds are processed within 5-7 business days after the return is picked up and inspected.
                        </p>
                    </div>

                    <div className='content'>
                        <h3>Contact Us</h3>
                        <p>
                            If you have further questions, feel free to reach out to our customer support team through the contact page.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Faq;

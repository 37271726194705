import React from 'react';
import './ProductBuyPage.css'
import Review from '../Review/Review';
import HoverDropdown from '../HoverDropdown/HoverDropdown';


const ProductBuyPage = () => {
    return (
   <>
      <HoverDropdown/>
        <div className="container mt-5 white-section section-padding">
            <div className="row pt-4">
                {/* Product Image Section */}
                <div className="col-md-5  product-image-container">
                    <img
                        src="https://rukminim2.flixcart.com/image/612/612/xif0q/shoe/6/o/g/7-29825830-41-hrx-by-hrithik-roshan-beige-original-imah5fdryqagqhyc.jpeg?q=70"
                        alt="ADIDAS CushUp Walking Shoes"
                        className="img-fluid"
                    />
                    <div className='buy-section-btn'>
                        <button className='btn-cart btn '>Add to Cart</button>
                        <button className='btn-buy btn '>Buy Now</button>
                    </div>
                </div>

                {/* Product Details Section */}
                <div className="col-md-7 scroll-section ">
                    <h2 className='product-title'>ADIDAS</h2>
                    <p className='product-short-detail'>CushUp Walking Shoes For Men (Black, 6)</p>
                    <p className="text-danger mb-0">
                        <strong>Special Price</strong>
                    </p>
                    <h4 className='price-section'>
                        ₹2,127 <span className="text-muted"><s className='real-price'>₹3,799</s></span> <span className='discount'>44% off</span>
                    </h4>
                    <p>
                        <strong className='rating'>3.9</strong> <span className="text-muted">/ 5 (20 ratings and 32 reviews)</span>
                    </p>

                    <h5>Available Offers</h5>
                    <ul className='offer-list'>
                        <li>Bank Offer: 5% Unlimited Cashback on Flipkart Axis Bank Credit Card T&C</li>
                        <li>Bank Offer: 10% off up to ₹1,500 on Axis Bank Credit Card (incl. migrated ones) on orders of ₹4,990 and above T&C</li>
                        <li>Bank Offer: 10% off up to ₹1,750 on Axis Bank Credit EMI (incl. migrated ones) on orders of ₹4,990 and above T&C</li>
                        <li>Special Price: Get extra 6% off (price inclusive of cashback/coupon) T&C</li>
                        <li>+16 more offers</li>
                    </ul>

                    {/* Color Selection */}
                    <div className="mt-4">
                        <h5>Color</h5>
                        <div className='product-color'>
                            <div className='color-container'>
                                <img
                                    src="https://rukminim2.flixcart.com/image/612/612/xif0q/shoe/6/o/g/7-29825830-41-hrx-by-hrithik-roshan-beige-original-imah5fdryqagqhyc.jpeg?q=70"
                                    alt="ADIDAS CushUp Walking Shoes"
                                    className="img-fluid"
                                />
                            </div>
                            <div className='color-container'>
                                <img
                                    src="https://rukminim2.flixcart.com/image/612/612/xif0q/shoe/6/o/g/7-29825830-41-hrx-by-hrithik-roshan-beige-original-imah5fdryqagqhyc.jpeg?q=70"
                                    alt="ADIDAS CushUp Walking Shoes"
                                    className="img-fluid"
                                />
                            </div>
                            <div className='color-container'>
                                <img
                                    src="https://rukminim2.flixcart.com/image/612/612/xif0q/shoe/6/o/g/7-29825830-41-hrx-by-hrithik-roshan-beige-original-imah5fdryqagqhyc.jpeg?q=70"
                                    alt="ADIDAS CushUp Walking Shoes"
                                    className="img-fluid"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Size Selection */}
                    <div className="mt-4">
                        <h5>Size</h5>
                        <div className='product-size-list'>
                            <div className='size-container size'>1</div>
                            <div className='size-container size'>2</div>
                            <div className='size-container size'>3</div>
                            <div className='size-container size'>4</div>
                            <div className='size-container size'>5</div>
                            <div className='size-container size'>6</div>
                        </div>
                    </div>

                    {/* Ratings Section */}
                    <div className="mt-4">
                        <h6>Customer Ratings:</h6>
                        <div className="d-flex align-items-center">
                            <span className="text-warning">⭐⭐⭐⭐⭐</span>
                            <span className="ms-2">3.9 / 5</span>
                        </div>
                    </div>

                    {/*  Products Details */}
                    <div className='mt-4'>
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                        aria-expanded="true" aria-controls="collapseOne">
                                        Product Detailes
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className="container mt-4">
                                            <h2>Product Details</h2>
                                            <div className="row product-info-row">
                                                <div className="col-md-4 product-info-label">Color</div>
                                                <div className="col-md-8 product-info-value">Black</div>
                                            </div>
                                            <div className="row product-info-row">
                                                <div className="col-md-4 product-info-label">Outer material</div>
                                                <div className="col-md-8 product-info-value">Textile</div>
                                            </div>
                                            <div className="row product-info-row">
                                                <div className="col-md-4 product-info-label">Model name</div>
                                                <div className="col-md-8 product-info-value">CushUp</div>
                                            </div>
                                            <div className="row product-info-row">
                                                <div className="col-md-4 product-info-label">Ideal for</div>
                                                <div className="col-md-8 product-info-value">Men</div>
                                            </div>
                                            <div className="row product-info-row">
                                                <div className="col-md-4 product-info-label">Occasion</div>
                                                <div className="col-md-8 product-info-value">Sports</div>
                                            </div>
                                            <div className="row product-info-row">
                                                <div className="col-md-4 product-info-label">Type For Sports</div>
                                                <div className="col-md-8 product-info-value">Walking Shoes</div>
                                            </div>
                                            <div className="row product-info-row">
                                                <div className="col-md-4 product-info-label">Net Quantity</div>
                                                <div className="col-md-8 product-info-value">1</div>
                                            </div>
                                            <div className="row product-info-row">
                                                <div className="col-md-4 product-info-label">Sole material</div>
                                                <div className="col-md-8 product-info-value">EVA</div>
                                            </div>
                                            <div className="row product-info-row">
                                                <div className="col-md-4 product-info-label">Closure</div>
                                                <div className="col-md-8 product-info-value">Lace-Ups</div>
                                            </div>
                                            <div className="row product-info-row">
                                                <div className="col-md-4 product-info-label">Weight</div>
                                                <div className="col-md-8 product-info-value">850 g (per single Shoe)</div>
                                            </div>
                                            <div className="row product-info-row">
                                                <div className="col-md-4 product-info-label">Upper Pattern</div>
                                                <div className="col-md-8 product-info-value">Striped</div>
                                            </div>
                                            <div className="row product-info-row">
                                                <div className="col-md-4 product-info-label">Sales package</div>
                                                <div className="col-md-8 product-info-value">1 Pair</div>
                                            </div>
                                            <div className="row product-info-row">
                                                <div className="col-md-4 product-info-label">Pack of</div>
                                                <div className="col-md-8 product-info-value">1</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Product Review */}
                    <div>
                        <Review />
                    </div>
                </div>
            </div>
        </div>
   </>
    );
};

export default ProductBuyPage;

import React from 'react';
import './Pages.css'
import { Link } from 'react-router-dom';

const EPRCompliance = () => {
  return (
    <div className='container'>
      <div className="security-container bg-white pages-conatiner">
        <h2>EBAZAAR E-WASTE RECYCLING POLICY</h2>

        <div className='content'>
          <h3>What is E-waste?</h3>
          <p>
            E-waste refers to discarded electronic devices, including computers, mobile phones, and appliances. It poses serious environmental and health risks.
          </p>
        </div>

        <div className='content'>
          <h3>Why recycle E-waste?</h3>
          <p>
            Recycling conserves resources, prevents pollution, and promotes a healthier environment by safely handling toxic materials found in e-waste.
          </p>
        </div>

        <div className='content'>
          <h3>How can you contribute to e-waste recycling?</h3>
          <p>
            You can hand over e-waste to authorized recyclers or contact EBAZAAR for collection services. Keep non-functional products isolated for proper disposal.
          </p>
        </div>

        <div className='content'>
          <h3>Collection Points</h3>
          <p>
            EBAZAAR has established collection points across various states. Please refer to the table below for details:
          </p>
          <table>
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Name of State/UT</th>
                <th>Name of Town/City</th>
                <th>Address of Collection Points</th>
                <th>Toll-Free Number</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>New Delhi</td>
                <td>New Delhi</td>
                <td>Plot No. 619, Block A, Near Tata Telco Service Station, Rangpuri, Mahipalpur, Delhi - 110037</td>
                <td>1800-123-8783</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Uttar Pradesh</td>
                <td>Lucknow</td>
                <td>S 317, Transport Nagar, Near RTO Office, Lucknow, UP- 226012</td>
                <td>1800-123-8783</td>
              </tr>
              {/* Aur rows add karne ke liye yahan code daalein */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EPRCompliance;

import React from 'react';
import { Link } from 'react-router-dom';
import './Pages.css';

const Security = () => {
    return (
        <div className='container'>
            <div className="security-container bg-white pages-conatiner">
                <h2>Safe and Secure Shopping</h2>

                <div className='content'>
                    <h3>Is making online payment secure on EBAZAAR?</h3>
                    <p>
                        Yes, making online payments is secure on EBAZAAR. We use industry-standard encryption and secure payment gateways to protect your payment information.
                    </p>
                </div>

                <div className='content'>
                    <h3>Does EBAZAAR store my credit/debit card information?</h3>
                    <p>
                        No. EBAZAAR only stores the last 4 digits of your card number for the purpose of card identification. Your full card details are never stored.
                    </p>
                </div>

                <div className='content'>
                    <h3>What credit/debit cards are accepted on EBAZAAR?</h3>
                    <p>
                        We accept VISA, MasterCard, Maestro, Rupay, American Express, Diner's Club, and Discover credit/debit cards.
                    </p>
                </div>

                <div className='content'>
                    <h3>Do you accept payment made by credit/debit cards issued in other countries?</h3>
                    <p>
                        Yes! We accept VISA, MasterCard, Maestro, and American Express credit/debit cards issued by banks in India and in the following countries: Australia, Austria, Belgium, Canada, Cyprus, Denmark, Finland, France, Germany, Ireland, Italy, Luxembourg, the Netherlands, New Zealand, Norway, Portugal, Singapore, Spain, Sweden, the UK, and the US. Please note that we do not accept internationally issued credit/debit cards for eGV payments/top-ups.
                    </p>
                </div>

                <div className='content'>
                    <h3>What other payment options are available on EBAZAAR?</h3>
                    <p>
                        Apart from Credit and Debit Cards, we accept payments via Internet Banking (covering 44 banks), Cash on Delivery, Equated Monthly Installments (EMI), E-Gift Vouchers, EBAZAAR Pay Later, UPI, Wallet, and Paytm Postpaid.
                    </p>
                </div>

                <div className='content'>
                    <h3>Privacy Policy</h3>
                    <p>
                        EBAZAAR respects your privacy and is committed to protecting it. For more details, please see our<Link to="/privacy">Privacy Policy</Link>
                    </p>
                </div>

                <div className='content'>
                    <h3>Contact Us</h3>
                    <p>
                        Couldn't find the information you need? Please <a href="/contact">Contact Us</a>.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Security;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Account.css';
import MyOrder from '../MyOrder/MyOrder';
import Profile from './Profile';
import Address from './Address';
import PanCard from './PanCard';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HoverDropdown from '../HoverDropdown/HoverDropdown';

function Account() {
    const [activeContent, setActiveContent] = useState('editProfile'); // Default content
    const navigate = useNavigate(); // Correctly initialize navigate

    const handleContentChange = (content) => {
        setActiveContent(content);
    };

    const orderClick = () => {
        navigate('/orderPage'); // Correctly navigate to the Order Page
    };

    return (
        <>
            <HoverDropdown/>
            <div className='container section-margin-top-bottom'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='white-section'>
                           <div className='d-flex d-lg-block justify-content-between' >
                           <ul className='profile-img ul-profile list-unstyled ps-0'>
                                <li className="ps-4"><h5><div><i className='fa fa-user'></i> Hello</div></h5></li>
                            </ul>
                            <h5 className='headline-icon' onClick={orderClick}>Your Order</h5>
                           </div>
                            <div className='accountArea mt-4'>
                                <h5 className='headline-icon'><i className='fa fa-user'></i> Account Setting</h5>
                                <ul className='ul-profile'>
                                    <li className={activeContent === 'editProfile' ? 'active' : ''} onClick={() => handleContentChange('editProfile')}>Account Information</li>
                                    <li className={activeContent === 'manageAdresses' ? 'active' : ''} onClick={() => handleContentChange('manageAdresses')}>Manage Addresses</li>
                                    <li className={activeContent === 'penCard' ? 'active' : ''} onClick={() => handleContentChange('penCard')}>Pan Card</li>
                                </ul>
                            </div>

                            {/* <div className='accountArea'>
                                <h5 className='headline-icon'><i className='fa fa-user'></i> Payments</h5>
                                <ul className='ul-profile'>
                                    <li className={activeContent === 'editProfile' ? 'active' : ''} onClick={() => handleContentChange('editProfile')}>Gift Card</li>
                                    <li className={activeContent === 'manageAdresses' ? 'active' : ''} onClick={() => handleContentChange('manageAdresses')}>Saved UPI</li>
                                    <li className={activeContent === 'penCard' ? 'active' : ''} onClick={() => handleContentChange('penCard')}>Saved Cards</li>
                                </ul>
                            </div> */}
                        </div>
                    </div>

                    <div className='col-md-9 white-section'>
                        <div>
                            {activeContent === 'editProfile' && <Profile />}
                            {activeContent === 'myOrder' && <MyOrder />}
                            {activeContent === 'manageAdresses' && <Address />}
                            {activeContent === 'penCard' && <PanCard />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Account;

import React from 'react';
import './Pages.css'

const Payment = () => {
    return (
        <>
            <div className='container ' >
                <div className="payment-container bg-white  pages-conatiner">
                    <h2>Payments</h2>
                    <p>
                    How do I pay for a purchase on EBAZAAR? EBAZAAR offers you multiple payment methods to choose from. You can use
                        various online modes of payment, ensuring a secure and hassle-free
                        transaction experience.
                    </p>
                    
                    <div className='content'>
                    <h3 className='page-headline'>Available Payment Methods</h3>
                    <ul>
                        <li>Internet Banking</li>
                        <li>Gift Card</li>
                        <li>Cash on Delivery</li>
                        <li>Digital Wallets</li>
                        <li>Credit/Debit Cards</li>
                    </ul>
                    </div>

                     <div className='content'>
                     <h3>Credit/Debit Card Payments</h3>
                    <p>
                        We accept Visa, MasterCard, Maestro, and American Express credit and
                        debit cards. For a secure transaction, you will need to provide your
                        card number, expiry date, and CVV.
                    </p>
                     </div>
                    <div className='content'>
                        
                    <h3>Cash on Delivery (C-o-D)</h3>
                    <p>
                        If you prefer not to pay online, you can choose the Cash on Delivery
                        option. This allows you to pay in cash at the time of delivery.
                    </p>
                    <p>
                        The maximum order value for C-o-D is ₹50,000. Note that this option is
                        strictly cash-only.
                    </p>
                    </div>
                   

                    <div className='content'>
                    <h3>EMI Options</h3>
                    <p>
                        EBAZAAR also offers an EMI (Equated Monthly Installments) option on
                        selected credit cards. You can choose from tenures of 3, 6, 9, 12,
                        18*, or 24 months*.
                    </p>
                    <p>
                        No processing fee is charged for availing of EBAZAAR's EMI payment
                        option.
                    </p>
                    </div>
                   
                      <div className='content'>
                        
                    <h3>Security of Online Payments</h3>
                    <p>
                        Your online transactions are secured with the highest levels of
                        encryption technology. EBAZAAR uses 256-bit encryption to protect your
                        card information.
                    </p>
                    <p>
                        All payments are processed through secure gateways managed by leading
                        banks, providing an additional layer of security.
                    </p>
                      </div>

                         <div className='content'>
                         <h3>FAQs</h3>
                    <h5>Can I use my bank's Internet Banking feature?</h5>
                    <p>
                        Yes, you can use your bank's Internet Banking service for making
                        payments on EBAZAAR. This allows direct funds transfer from your bank
                        account.
                    </p>
                         </div>

                     <div className='content'>
                     <h5>What to do if my transaction fails?</h5>
                    <p>
                        If your transaction fails, please check your payment details and try
                        again. If the issue persists, contact our customer support for
                        assistance.
                    </p>

                     </div>
                     <div className='content'>
                     <h5>Contact Us</h5>
                    <p>
                        For any payment-related queries, feel free to reach out to our customer
                        support team through our contact page.
                    </p>
                     </div>

                </div>
            </div>
        </>
    );
};

export default Payment;

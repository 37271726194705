import React from 'react';
import './Pages.css';

const Shipping = () => {
    return (
        <>
            <div className='container ' >
                <div className=" bg-white pages-conatiner">
                    <h2>Shipping Information</h2>
                    <p>
                        How do I get my orders delivered on EBAZAAR? EBAZAAR offers fast and reliable delivery services across various locations. You can track your orders and enjoy hassle-free delivery at your doorstep.
                    </p>
                    
                    <div className='content'>
                    <h3 className='page-headline'>Shipping Methods</h3>
                    <ul>
                        <li>Standard Delivery</li>
                        <li>Express Delivery</li>
                        <li>Same-Day Delivery</li>
                        <li>Store Pickup</li>
                    </ul>
                    </div>

                    <div className='content'>
                    <h3>Standard Delivery</h3>
                    <p>
                        With standard delivery, your order will be delivered within 3-7 business days. This option is available for most locations and provides a balance between cost and delivery speed.
                    </p>
                    </div>

                    <div className='content'>
                    <h3>Express Delivery</h3>
                    <p>
                        Express delivery ensures your order arrives within 1-3 business days. This is ideal for urgent needs and comes with an additional cost.
                    </p>
                    </div>

                    <div className='content'>
                    <h3>Same-Day Delivery</h3>
                    <p>
                        For select locations, you can opt for same-day delivery if you place your order before a certain cut-off time. Check availability during checkout.
                    </p>
                    </div>

                    <div className='content'>
                    <h3>Shipping Costs</h3>
                    <p>
                        Shipping costs vary based on the delivery method selected and your location. The exact shipping cost will be displayed at checkout.
                    </p>
                    </div>

                    <div className='content'>
                    <h3>International Shipping</h3>
                    <p>
                        EBAZAAR offers international shipping to select countries. Please note that shipping costs and delivery times vary by country.
                    </p>
                    </div>

                    <div className='content'>
                    <h3>Tracking Your Order</h3>
                    <p>
                        Once your order has been shipped, you will receive an email with tracking details. You can also track your order directly from your EBAZAAR account.
                    </p>
                    </div>

                    <div className='content'>
                    <h3>Contact Us</h3>
                    <p>
                        For any shipping-related queries, feel free to reach out to our customer support team through our contact page.
                    </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Shipping;

import React from 'react';
import './produts_300.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useNavigate } from 'react-router-dom';

const options = {
  responsive: {
    0: {
      items: 2,
    },
    768: {
      items: 6,
    },
  },
  nav: true,
  dots: false,
};

// Moved the cardData array here as it is correctly defined.
const cardData = [
  {
    img: "https://rukminim2.flixcart.com/image/612/612/xif0q/sandal/o/i/e/8-411-heighten-white-original-imagquk58ya3vvqm.jpeg?q=70",
    title: "Women's Flats",
    description: " 300",
  },
  {
    img: "https://rukminim2.flixcart.com/image/612/612/xif0q/sandal/f/9/m/8-10613510686-8-khadim-s-beige-original-imah4ebgrda5bt4d.jpeg?q=70",
    title: "Women's Flats",
    description: " 300",
  },
  {
    img: "https://rukminim2.flixcart.com/image/612/612/xif0q/vehicle-lubricant/6/s/z/1000-coolant-pink-1-ltr-p1-esson-original-imagyeyd9cptrvae.jpeg?q=70",
    title: "Home Product",
    description: " 300",
  },
  {
    img: "https://rukminim2.flixcart.com/image/612/612/xif0q/perfume/g/a/z/40-date-woman-glam-woman-perfume-combo-2x20ml-with-floral-woody-original-imahyrrjgmjkfrsg.jpeg?q=70",
    title: "Perfume",
    description: " 300",
  },
  {
    img: "https://rukminim2.flixcart.com/image/612/612/xif0q/perfume/g/a/z/40-date-woman-glam-woman-perfume-combo-2x20ml-with-floral-woody-original-imahyrrjgmjkfrsg.jpeg?q=70",
    title: "Perfume",
    description: " 300",
  },
  {
    img: "https://rukminim2.flixcart.com/image/612/612/xif0q/perfume/g/a/z/40-date-woman-glam-woman-perfume-combo-2x20ml-with-floral-woody-original-imahyrrjgmjkfrsg.jpeg?q=70",
    title: "Perfume",
    description: " 300",
  },
  {
      img: "https://rukminim2.flixcart.com/image/612/612/xif0q/perfume/g/a/z/40-date-woman-glam-woman-perfume-combo-2x20ml-with-floral-woody-original-imahyrrjgmjkfrsg.jpeg?q=70",
      title: "Perfume",
      description: " 300",
    },
    {
      img: "https://rukminim2.flixcart.com/image/612/612/xif0q/perfume/g/a/z/40-date-woman-glam-woman-perfume-combo-2x20ml-with-floral-woody-original-imahyrrjgmjkfrsg.jpeg?q=70",
      title: "Perfume",
      description: " 300",
    },
];


function Product_under_300() {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate('/productlist'); // Correctly navigating to ProductList page
  };
  return (
    <>
      <div className='product_300'>
        <div className='container-fluid'>
          <div className='white-section'>
            <div className='d-flex justify-content-between align-items-center'>
              <h4 className='headline'>Under RS 300</h4>
              <i className="fas fa-chevron-right right-chev"></i>
            </div>
            <OwlCarousel className="owl-theme" {...options} >
              {cardData.map((card, index) => (
                <div onClick={handleCardClick} className="item" key={index} >
                  <div className="card text-start">
                    <div className='img-box'>
                      <img className="card-img-top" src={card.img} alt={card.title} />
                    </div>
                    <div className="card-body">
                      <h4 className="card-title">{card.title}</h4>
                      <p className="card-text">Under &#8377;{card.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </>
  );
}

export default Product_under_300;

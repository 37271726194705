import React, { useState } from 'react';
import { TextField, Button, Typography, FormControlLabel, Checkbox } from '@mui/material';

function PanCard() {
  const [panNumber, setPanNumber] = useState('');
  const [fullName, setFullName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [accepted, setAccepted] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = () => {
    // Add submit logic
    console.log('PAN:', panNumber, 'Name:', fullName, 'File:', selectedFile);
  };

  return (
  <>
      <div style={{ padding: '20px', maxWidth: '600px', }}>
      <Typography variant="h5" gutterBottom>PAN Card Information</Typography>

      <TextField
        label="PAN Card Number"
        variant="outlined"
        fullWidth
        margin="normal"
        value={panNumber}
        onChange={(e) => setPanNumber(e.target.value)}
      />

      <TextField
        label="Full Name"
        variant="outlined"
        fullWidth
        margin="normal"
        value={fullName}
        onChange={(e) => setFullName(e.target.value)}
      />

      <div className='mb-4'><Typography variant="body1" gutterBottom>Upload PAN Card (Only JPEG file is allowed)</Typography></div>
      <input type="file" accept=".jpeg" onChange={handleFileChange} />
      <Typography variant="body2" color="textSecondary">{selectedFile ? selectedFile.name : 'No file chosen'}</Typography>

      <Typography variant="body2" gutterBottom style={{ marginTop: '20px' }}>
        I do hereby declare that PAN furnished/stated above is correct and belongs to me, registered as an account holder with www.flipkart.com.
        I further declare that I shall solely be held responsible for the consequences, in case of any false PAN declaration.
      </Typography>

      <div className='mb-4'>   <FormControlLabel
        control={<Checkbox checked={accepted} onChange={() => setAccepted(!accepted)} />}
        label="I accept the declaration"
      /></div>

      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleSubmit} 
        disabled={!accepted}
        style={{ marginRight: '10px' }}
      >
        UPLOAD
      </Button>
      
      <Button variant="outlined" color="secondary">Read Terms & Conditions</Button>
    </div>
  </>
  )
}

export default PanCard
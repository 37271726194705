import React from 'react';
import './Pages.css';

const CancellationRefund = () => {
    return (
        <>
            <div className='container'>
                <div className="payment-container bg-white pages-conatiner">
                    <h2>Cancellation & Refund Policy</h2>
                    <p>
                        At EBAZAAR, we aim to provide the best shopping experience. However, if you need to cancel or return an order, we have a straightforward cancellation and refund process to ensure a smooth experience.
                    </p>

                    <div className='content'>
                        <h3 className='page-headline'>Order Cancellation</h3>
                        <p>
                            You can cancel your order before it has been shipped. To cancel an order:
                        </p>
                        <p>
                            - Log into your EBAZAAR account and navigate to "My Orders." <br />
                            - Select the order you wish to cancel. <br />
                            - If the order has not been shipped, click on the "Cancel" button.
                        </p>
                        <p>
                            Once canceled, the refund will be processed automatically if you’ve made an advance payment.
                        </p>
                    </div>

                    <div className='content'>
                        <h3>Return and Refund</h3>
                        <p>
                            If you’re unsatisfied with your purchase, you can initiate a return request within 7 days of receiving the product. Ensure the product is in its original condition with all tags and packaging intact. To return an item:
                        </p>
                        <p>
                            - Log into your account and go to "My Orders."<br />
                            - Select the item you wish to return and click on the "Return" button.<br />
                            - Follow the steps and provide a reason for the return.
                        </p>
                        <p>
                            Once your return is approved and picked up, your refund will be processed within 5-7 business days.
                        </p>
                    </div>

                    <div className='content'>
                        <h3>Refund Process</h3>
                        <p>
                            Refunds are processed through the original payment method. If you paid by:
                        </p>
                        <p>
                            - Credit/Debit Card: The refund will be credited to your card within 5-7 business days. <br />
                            - Net Banking: The refund will be credited to your bank account within 5-7 business days. <br />
                            - Cash on Delivery (C-o-D): For COD orders, the refund will be initiated through a bank transfer once you provide your bank details.
                        </p>
                    </div>

                    <div className='content'>
                        <h3>FAQs</h3>
                        <p><strong>Can I cancel an order after it’s shipped?</strong></p>
                        <p>No, once an order is shipped, it cannot be canceled. However, you may return the item after it has been delivered.</p>

                        <p><strong>How long does it take to process a refund?</strong></p>
                        <p>Refunds typically take 5-7 business days once the return or cancellation is confirmed.</p>

                        <p><strong>What if my refund is delayed?</strong></p>
                        <p>If your refund is delayed, please contact our customer support team, and we will assist you.</p>
                    </div>

                    <div className='content'>
                        <h3>Contact Us</h3>
                        <p>
                            For any cancellation or refund-related queries, feel free to reach out to our customer support team through our contact page.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CancellationRefund;

import React from 'react';
import './Pages.css';

const TermsAndConditions = () => {
    return (
        <div className='container'>
            <div className="terms-container bg-white pages-conatiner">
                <h2>Terms and Conditions</h2>
                <p>
                    Welcome to EBAZAAR! By accessing or using our website, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.
                </p>

                <div className='content'>
                    <h3>1. Acceptance of Terms</h3>
                    <p>
                        By using our services, you confirm that you are at least 18 years old or have the consent of a parent or guardian to use our site. If you do not agree with these terms, please do not use our website.
                    </p>
                </div>

                <div className='content'>
                    <h3>2. Account Registration</h3>
                    <p>
                        To access certain features of our site, you may need to register for an account. You are responsible for maintaining the confidentiality of your account information and for all activities under your account.
                    </p>
                </div>

                <div className='content'>
                    <h3>3. Product Information</h3>
                    <p>
                        We strive to ensure that the information about our products is accurate. However, we do not guarantee that the product descriptions, prices, or other content on the site are complete, reliable, or error-free.
                    </p>
                </div>

                <div className='content'>
                    <h3>4. Orders and Payments</h3>
                    <p>
                        By placing an order, you agree to pay for the products in accordance with the pricing and payment terms set forth on our site. We reserve the right to refuse or cancel any order for any reason.
                    </p>
                </div>

                <div className='content'>
                    <h3>5. Shipping and Delivery</h3>
                    <p>
                        We aim to deliver your orders in a timely manner. However, we are not responsible for delays caused by third-party shipping providers, natural disasters, or other unforeseen circumstances.
                    </p>
                </div>

                <div className='content'>
                    <h3>6. Returns and Refunds</h3>
                    <p>
                        Our return and refund policy is outlined separately. Please review our policy to understand your rights regarding returns and refunds.
                    </p>
                </div>

                <div className='content'>
                    <h3>7. Limitation of Liability</h3>
                    <p>
                        EBAZAAR shall not be liable for any indirect, incidental, or consequential damages arising out of your use of our website or products. Your sole remedy for dissatisfaction with our site or services is to stop using it.
                    </p>
                </div>

                <div className='content'>
                    <h3>8. Governing Law</h3>
                    <p>
                        These terms and conditions are governed by and construed in accordance with the laws of India. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the courts of India.
                    </p>
                </div>

                <div className='content'>
                    <h3>9. Changes to Terms</h3>
                    <p>
                        We reserve the right to modify these terms and conditions at any time. Any changes will be effective immediately upon posting on our site. Your continued use of the site constitutes acceptance of the new terms.
                    </p>
                </div>

                <div className='content'>
                    <h3>10. Contact Us</h3>
                    <p>
                        If you have any questions about these terms and conditions, please contact our customer support team through our contact page.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;

import React from 'react';
// import ReactHover, { Trigger, Hover } from 'react-hover';
import './HoverDropdown.css'

// const options = {
//   followCursor: false,
//   shiftX: 0,
//   shiftY: 20,
// };

const HoverDropdown = () => {
  return (
      <>
      <div className=' d-none d-lg-block '>
      <div className='shadow-bottom  white-section'>
                   <ul className="categories-nav me-auto mb-2 mb-lg-0 justify-content-between w-100">
        <li className="nav-item dropdown ">
            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
             Electronics
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li className="d-flex justify-content-between">
           
                    <ul>
                        <li><a className="dropdown-item" href="#">Action1</a></li>
                         <li>1</li>
                         <li>2</li>
                         <li>3</li>
                         <li>4</li>
                         <li>5</li>
                         <li>6</li>
                    </ul>
         
                    <ul>
                        <li><a className="dropdown-item" href="#">Action2</a></li>
                    </ul>
            
             
                    <ul>
                        <li><a className="dropdown-item" href="#">Action3</a></li>
                    </ul>
              
              
                    <ul>
                        <li><a className="dropdown-item" href="#">Action4</a></li>
                    </ul>
              
            
                    <ul>
                        <li><a className="dropdown-item" href="#">Action5</a></li>
                    </ul>
             
            </li>
             
            </ul>
          </li>

          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            TVs & Appliances
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li className="d-flex justify-content-between">
            </li>
          
            </ul>
          </li>
          
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Sports, Books & More
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li className="d-flex justify-content-between">
            </li>
          
            </ul>
          </li>
                 
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Home & Furniture
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li className="d-flex justify-content-between">
            </li>
          
            </ul>
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
             Baby
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li className="d-flex justify-content-between">
           
                    <ul>
                        <li><a className="dropdown-item" href="#">Action1</a></li>
                         <li>1</li>
                         <li>2</li>
                         <li>3</li>
                         <li>4</li>
                         <li>5</li>
                         <li>6</li>
                    </ul>
         
                    <ul>
                        <li><a className="dropdown-item" href="#">Action2</a></li>
                    </ul>
            
             
                    <ul>
                        <li><a className="dropdown-item" href="#">Action3</a></li>
                    </ul>
              
              
                    <ul>
                        <li><a className="dropdown-item" href="#">Action4</a></li>
                    </ul>
              
            
                    <ul>
                        <li><a className="dropdown-item" href="#">Action5</a></li>
                    </ul>
             
            </li>
             
            </ul>
          </li> <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Women
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li className="d-flex justify-content-between">
           
                    <ul>
                        <li><a className="dropdown-item" href="#">Action1</a></li>
                         <li>1</li>
                         <li>2</li>
                         <li>3</li>
                         <li>4</li>
                         <li>5</li>
                         <li>6</li>
                    </ul>
         
                    <ul>
                        <li><a className="dropdown-item" href="#">Action2</a></li>
                    </ul>
            
             
                    <ul>
                        <li><a className="dropdown-item" href="#">Action3</a></li>
                    </ul>
              
              
                    <ul>
                        <li><a className="dropdown-item" href="#">Action4</a></li>
                    </ul>
              
            
                    <ul>
                        <li><a className="dropdown-item" href="#">Action5</a></li>
                    </ul>
             
            </li>
             
            </ul>
          </li> 
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
             Men
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li className="d-flex justify-content-between">
            </li>
          
            </ul>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">Flights</a>
            
          </li>
          <li><a className="nav-link" href="#">Offer Zome</a></li>
        </ul>
       </div>
       {/* <div class="btn-group dropstart">
  <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
    Dropstart
  </button>
  <ul class="dropdown-menu">
  <li><a className="dropdown-item" href="#">Action</a></li>
                <li><a className="dropdown-item" href="#">Another action</a></li>
                <li><a className="dropdown-item" href="#">Something else here</a></li>
  </ul>
</div> */}
      </div>
      </>
  );
};

export default HoverDropdown;

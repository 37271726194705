import React, { useState } from 'react';
import { TextField, Button, RadioGroup, FormControlLabel, Radio, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Profile() {
    const [isEditable, setIsEditable] = useState(false);
  
    const handleEditClick = () => {
      setIsEditable(!isEditable);
    };
  return (
    <div style={{ padding: '20px' }}>
      <div className='row'>
        <div className='col-md-4'> <Typography variant="h5" gutterBottom>Personal Information</Typography></div>
        <div className='col-md-8'>  <Button variant="contained" onClick={handleEditClick}>
      {isEditable ? 'Save' : 'Edit'}
    </Button></div>
      </div>
   
  

    <div className='row' style={{ marginTop: '20px' }}>
       <div className='col-md-6'> <TextField 
        label="First Name" 
        variant="outlined" 
        disabled={!isEditable} 
        fullWidth 
        margin="normal" 
      /></div>
      <div className='col-md-6'>
      <TextField 
        label="Last Name" 
        variant="outlined" 
        disabled={!isEditable} 
        fullWidth 
        margin="normal" 
      />
      </div>
      <div className='col-12'>
      <RadioGroup row aria-label="gender" name="gender">
        <FormControlLabel value="male" control={<Radio disabled={!isEditable}/>} label="Male" />
        <FormControlLabel value="female" control={<Radio disabled={!isEditable}/>} label="Female" />
      </RadioGroup>
      </div>
      <div className='col-md-6'>  
      <TextField 
        label="Mobile" 
        variant="outlined" 
        disabled={!isEditable} 
        fullWidth 
        margin="normal" 
      /></div>
      <div className='col-md-6'>
      <TextField 
        label="Email" 
        variant="outlined" 
        disabled={!isEditable} 
        fullWidth 
        margin="normal" 
      />
      </div>
    
     
    </div>

    {/* FAQ Section */}
    <div style={{ marginTop: '40px' }}>
      <Typography variant="h5" gutterBottom>FAQs</Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>What are the shipping charges?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Shipping charges vary based on the location and the value of your order. 
            Free shipping is available on orders above a certain amount.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>What is the return policy?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can return items within 30 days of delivery. Make sure the item is in its original condition.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>What payment methods are accepted?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We accept credit/debit cards, net banking, and popular digital wallets.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  </div>
  )
}

export default Profile
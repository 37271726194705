import React from 'react'
import AboutBanner from './about-banner.png'
import './About.css'
function About() {
  return (
    <>
      <div className='container-fluid mt-2'>
        <img className='img-fluid' src={AboutBanner} />
      </div>
      <div className="container about white-section section-padding section-margin-top-bottom">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            {/* <li class="breadcrumb-item"><a href="#">Footwear</a></li> */}
            <li class="breadcrumb-item active" aria-current="page">About</li>
          </ol>
        </nav>
        <div className="row mb-5">
          <div className="col-md-6">
            <div className='card'>
              <div className='card-body'>
                <div className='card-text'>
                  At EBAZAAR, we are dedicated to providing the best business solutions, offering a seamless shopping experience for our customers. With a focus on quality and customer satisfaction, we bring a wide range of products to meet your needs. Trust, reliability, and innovation are the cornerstones
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Our Mission</h5>
                <p className="card-text">
                  At EBAZAAR, our mission is to provide a seamless shopping experience
                  by connecting customers with quality products at the best prices.
                  We strive to be the leading e-commerce platform, offering convenience
                  and reliability in every transaction.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Our Values</h5>
                <p className="card-text">
                  We believe in integrity, customer satisfaction, and continuous
                  improvement. Our values guide us in our operations and ensure
                  that we remain committed to serving our customers with honesty
                  and excellence.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <img className='img-fluid' src='https://cdn.pixabay.com/photo/2024/03/26/11/57/e-commerce-8656646_640.jpg' />
          </div>
        </div>

        {/*  */}
        <div className="row about-card-two">
          {/* Customer Commitment Card */}
          <div className="col-md-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Customer Commitment</h5>
                <p className="card-text">
                  Customer trust is central to our business. We ensure a secure and hassle-free shopping experience through encrypted payment gateways and exceptional customer support.
                </p>
              </div>
            </div>
          </div>

          {/* Milestones Card */}
          <div className="col-md-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Milestones</h5>
                <p className="card-text">
                  We are proud to have served over 1 million satisfied customers, consistently delivering quality products and top-notch service.
                </p>
              </div>
            </div>
          </div>

          {/* Sustainability Efforts Card */}
          <div className="col-md-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Sustainability Efforts</h5>
                <p className="card-text">
                  We are committed to eco-friendly practices by reducing packaging waste and adopting sustainable shipping methods to minimize our environmental impact.
                </p>
              </div>
            </div>
          </div>

          {/* Why Choose Us Card */}
          <div className="col-md-3">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Why Choose Us?</h5>
                <p className="card-text">
                  We offer a vast variety of products, secure shopping, and reliable customer support, making us the preferred choice for millions of users.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-md-5 '>
            <img className='img-fluid' src='https://cdn.pixabay.com/photo/2024/04/11/16/20/business-8690142_640.jpg' />
          </div>
          <div className='col-md-7 padding-20-5'>
            <ul className='ul-one'>
              <li><strong>Fast & Reliable Shipping:</strong> We ensure quick and reliable delivery to your doorstep, partnering with the best logistics providers.</li>
              <li><strong>Free Delivery on Select Orders:</strong> Enjoy free shipping on orders that meet a minimum purchase value, making your shopping experience more affordable.</li>
              <li><strong>Track Your Order:</strong> Stay updated with real-time tracking from the moment your order is dispatched until it reaches you.</li>
              <li><strong>Safe Packaging:</strong> We prioritize secure packaging to ensure your products arrive in perfect condition, no matter the distance.</li>
              <li><strong>Nationwide Coverage:</strong> We deliver to most locations across the country, ensuring accessibility to all customers, even in remote areas.</li>
            </ul>

          </div>
        </div>
      </div>
    </>
  )
}

export default About
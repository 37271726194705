import React, { useState } from 'react';
import { IconButton, Popover, MenuItem, MenuList, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './DropdownWithHover.css'

function DropdownWithHover() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        className='bb'
      >
        <MoreVertIcon />
      </IconButton>

      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        PaperProps={{
          onMouseEnter: handlePopoverOpen,
          onMouseLeave: handlePopoverClose,
          className: 'dropdown-menux'  // Custom class apply karna
        }}
      >
        <MenuList>
          <Typography sx={{ p: 1 }}>New customer? Sign Up</Typography>
          <MenuItem>My Profile</MenuItem>
          <MenuItem>Flipkart Plus Zone</MenuItem>
          <MenuItem>Orders</MenuItem>
          <MenuItem>Wishlist</MenuItem>
          <MenuItem>Rewards</MenuItem>
          <MenuItem>Gift Cards</MenuItem>
        </MenuList>
      </Popover>
    </div>
  );
}

export default DropdownWithHover;
